import { defineStore } from 'pinia';

export const useStoreActivate = defineStore('eatery-activate', {
  persist: true,
  state: () => ({
    invite_id: null,
    status: {},
    is_portal_terms: false,
    invite_error: null,
    invite_success: null,
    is_new_company_create: false,
  }),
});