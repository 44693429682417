import config from "@/configs/common";
import storeAuth from "@/helpers/store/storeAuthHelper"
import { apiGetListLoop } from "@/helpers/api_common"

import api_associate from "./api/associate";
import api_company from "./api/company.js";
import api_employee from "./api/employee";
import api_brand from "./api/brand.js";
import api_area from "./api/area.js";
import api_position from "./api/position.js";
import api_facility from "./api/facility.js";
import api_forum from "./api/forum.js";
import api_shop from "./api/shop.js";
import api_shopstaff from "./api/shopstaff.js";
import api_staffgroup from "./api/staffgroup.js";
import api_staff from "./api/staff.js";
import api_role from "./api/role.js";
import api_policy from "./api/policy.js";
import api_chat from "./api/chat";
import api_file from "./api/file";
import api_notification from "./api/notification";
import api_service from "./api/service";
import api_upload from "./api/upload";
import api_franchise from "./api/franchise";
import api_contact_form from "./api/contact_form";


// アソシエイツ
export async function apiAssociateGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_associate.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiAssociateUpdate(associate) {
  return api_associate.update(config.api, storeAuth.storeGetSelectedStaffId(), associate);
}
export function apiAssociateGetInfo(associate_id, include_closed_shop = 1) {
  return api_associate.getInfo(config.api, storeAuth.storeGetSelectedStaffId(), associate_id, include_closed_shop);
}
export function apiEmployeeProfileGetInfo(associate_id) {
  return api_associate.getEmployeeProfileInfo(config.api, storeAuth.storeGetSelectedStaffId(), associate_id)
}
export function apiAssociateJoinShops(associate_id, shops) {
  return api_associate.joinShop(config.api, storeAuth.storeGetSelectedStaffId(), associate_id, shops);
}
export function apiAssociateExitShops(associate_id, shops) {
  return api_associate.exitShops(config.api, storeAuth.storeGetSelectedStaffId(), associate_id, shops);
}
export function apiAssociateGetAreas(associate_id) {
  return api_associate.getJoinAreas(config.api, storeAuth.storeGetSelectedStaffId(), associate_id);
}

// 企業
export function apiCompanyGetInfo() {
  return api_company.getInfo(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiCompanyGetList(page = 1) {
  return api_company.getList(config.api, storeAuth.storeGetAccountId(), page);
}
export function apiCompanyUpdate(company) {
  return api_company.update(config.api, storeAuth.storeGetSelectedStaffId(), company);
}
export function apiCompanyGenerateCode(conditions = {}) {
  return api_company.newCode(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// 従業員
export function apiEmployeeGetList(page = 1) {
  return api_employee.getList(config.api, storeAuth.storeGetSelectedStaffId(), page);
}
export function apiEmployeeSearch(conditions) {
  return api_employee.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiMasterEmployeeSearch(conditions) {
  return api_employee.getMasterList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiEmployeeGetPrevList(shop_id) {
  return api_employee.getList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id);
}
export function apiEmployeeCreate(employee) {
  return api_employee.create(config.api, storeAuth.storeGetSelectedStaffId(), employee);
}
export function apiEmployeeUpdate(employee) {
  return api_employee.update(config.api, storeAuth.storeGetSelectedStaffId(), employee);
}
export function apiEmployeeGetMyShopList(shop_id, page = 1) {
  return api_employee.getMyShopList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, page);
}
export function apiEmployeeSearchMyShop(conditions) {
  return api_employee.searchMyShop(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiEmployeeGetMyShopPreList(shop_id) {
  return api_employee.getMyShopPreList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id);
}

// スタッフグループ
export function apiStaffGroupGetOwnList(page = 1) {
  return api_staffgroup.getOwnList(config.api, storeAuth.storeGetSelectedStaffId(), page);
}
export async function apiStaffGroupGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_staffgroup.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiStaffGroupCreate(staffgroup) {
  return api_staffgroup.create(config.api, storeAuth.storeGetSelectedStaffId(), staffgroup);
}
export function apiStaffGroupUpdate(staffgroup, roles, members, sso) {
  return api_staffgroup.update(config.api, storeAuth.storeGetSelectedStaffId(), staffgroup, roles, members, sso);
}
export function apiStaffGroupEmployeeList(conditions) {
  return api_staffgroup.getEmployeeList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// ロール
export function apiGetRoleList(page = 1) {
  return api_role.getList(config.api, storeAuth.storeGetSelectedStaffId(), page);
}
export function apiRoleCreate(role) {
  return api_role.create(config.api, storeAuth.storeGetSelectedStaffId(), role);
}
export function apiRoleUpdate(role, policies) {
  return api_role.update(config.api, storeAuth.storeGetSelectedStaffId(), role, policies);
}

// ポリシー
export async function apiGetPolicyList(page) {
  return await apiGetListLoop((_page) => {
    return api_policy.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}

// サービス
export function apiServiceList(page = 1) {
  return api_service.getList(config.api, storeAuth.storeGetSelectedStaffId(), page);
}

// 店舗
export function apiShopGetOwnList(page = 1) {
  return api_shop.getOwnList(config.api, storeAuth.storeGetSelectedStaffId(), page);
}
export function apiShopGetList(page = 1) {
  return api_shop.getList(config.api, storeAuth.storeGetSelectedStaffId(), page);
}
export async function apiShopSearch(conditions) {
  const results = await api_shop.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
  results.data.forEach(e => {
    e.areas = e.areas.filter(v => v.status == 0 || v.status == undefined)
    e.brands = e.brands.filter(v => v.status == 0 || v.status == undefined)
  })
  return results
}
export function apiShopCreate(shop) {
  return api_shop.create(config.api, storeAuth.storeGetSelectedStaffId(), shop);
}
export function apiShopUpdate(shop, brand, shop_times) {
  return api_shop.update(config.api, storeAuth.storeGetSelectedStaffId(), shop, brand, shop_times);
}
export function apiShopAddArea(shop_id, area_id) {
  return api_shop.addArea(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, area_id);
}
export function apiShopRemoveArea(shop_id, area_id) {
  return api_shop.removeArea(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, area_id);
}
export function apiShopUpdateTimes(shop_id, shop_times) {
  return api_shop.updateTimes(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, shop_times);
}
export function apiShopGetStaffList(shop_id, with_shop_user = 0, page = 1) {
  return api_shop.getStaffList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, with_shop_user, page);
}
export function apiShopGetCalendar(shop_id, spec_date) {
  return api_shop.getCalendar(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, spec_date);
}
export function apiShopStatusVManage(shop_id) {
  return api_shop.statusVManage(config.api, storeAuth.storeGetSelectedStaffId(), shop_id);
}
export function apiShopCreateVManage(vmanage) {
  return api_shop.createVManage(config.api, storeAuth.storeGetSelectedStaffId(), vmanage);
}
export function apiShopUpdateVManage(vmanage) {
  return api_shop.updateVManage(config.api, storeAuth.storeGetSelectedStaffId(), vmanage);
}
export function apiShopUpdateIPAddress(shop_id, shop_ip_info) {
  return api_shop.updateIPAddress(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, shop_ip_info);
}
export function apiShopIPAddressList(shop_id) {
  return api_shop.listIPAddress(config.api, storeAuth.storeGetSelectedStaffId(), shop_id);
}
export function apiShopAccountCreate(shop_id, signup) {
  return api_shop.createShopAccount(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, signup);
}
export function apiShopAccountUpdate(shop_id, user) {
  return api_shop.updateShopAccount(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, user);
}

// 店舗休業
export async function apiShopGetHolidayList(shop_id, page) {
  return await apiGetListLoop((_page) => {
    return api_shop.getHolidayList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page);
  }, page, 10)
}
export function apiShopAddHoliday(shop) {
  return api_shop.addHoliday(config.api, storeAuth.storeGetSelectedStaffId(), shop);
}
export function apiShopRemoveHoliday(shop_holiday_id) {
  return api_shop.removeHoliday(config.api, storeAuth.storeGetSelectedStaffId(), shop_holiday_id);
}

// 店舗スタッフ
export function apiShopStaffRoleAdd(staff_id, role_ids) {
  return api_shopstaff.roleAdd(config.api, storeAuth.storeGetSelectedStaffId(), staff_id, role_ids);
}
export function apiShopStaffRoleDelete(staff_id, role_ids) {
  return api_shopstaff.roleDelete(config.api, storeAuth.storeGetSelectedStaffId(), staff_id, role_ids);
}

// スタッフ
export function apiStaffPenddingList(page = 1) {
  return api_shopstaff.getList(storeAuth.storeGetSelectedStaffId(), page);
}
export function apiStaffApprove(approve_id, approval_result) {
  return api_shopstaff.approve(config.api, storeAuth.storeGetSelectedStaffId(), approve_id, approval_result);
}
export function apiStaffUpdate(staff, sso) {
  return api_staff.update(config.api, storeAuth.storeGetSelectedStaffId(), staff, sso);
}

// ブランド
export async function apiBrandGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_brand.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiBrandCreate(brand) {
  return api_brand.create(config.api, storeAuth.storeGetSelectedStaffId(), brand);
}
export function apiBrandUpdate(brand) {
  return api_brand.update(config.api, storeAuth.storeGetSelectedStaffId(), brand);
}
export function apiBrandGenerateCode(conditions = {}) {
  return api_brand.newCode(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

// エリア
export async function apiGetAreaList(page) {
  let results = await await apiGetListLoop((_page) => {
    return api_area.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
  results.data.map(v => {
    Object.assign(v, JSON.parse(JSON.stringify(v.areas)))
  })
  return results
}
export function apiAreaCreate(area) {
  return api_area.create(config.api, storeAuth.storeGetSelectedStaffId(), area);
}
export function apiAreaUpdate(area) {
  return api_area.update(config.api, storeAuth.storeGetSelectedStaffId(), area);
}
export function apiAreaGenerateCode(conditions = {}) {
  return api_area.newCode(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiAreaGetAssociates(area_id) {
  return api_area.getAssociates(config.api, storeAuth.storeGetSelectedStaffId(), area_id);
}
export function apiAreaAddAssociates(area_associates) {
  return api_area.addAssociates(config.api, storeAuth.storeGetSelectedStaffId(), area_associates);
}
export function apiAreaRemoveAssociates(area_associates) {
  return api_area.removeAssociates(config.api, storeAuth.storeGetSelectedStaffId(), area_associates);
}
export function apiAreaUpdateAssociate(area_associate) {
  return api_area.updateAssociate(config.api, storeAuth.storeGetSelectedStaffId(), area_associate);
}

// ポジション
export async function apiGetPositionList(conditions) {
  return api_position.getList(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiPositionCreate(position) {
  return api_position.create(config.api, storeAuth.storeGetSelectedStaffId(), position);
}
export function apiPositionUpdate(position) {
  return api_position.update(config.api, storeAuth.storeGetSelectedStaffId(), position);
}

// 設備
export async function apiFacilityGetList(page) {
  return await apiGetListLoop((_page) => {
    return api_facility.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiFacilitySearch(conditions) {
  return api_facility.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiFacilityCreate(facility) {
  return api_facility.create(config.api, storeAuth.storeGetSelectedStaffId(), facility);
}
export function apiFacilityUpdate(facility) {
  return api_facility.update(config.api, storeAuth.storeGetSelectedStaffId(), facility);
}
// 店舗設備
export async function apiShopFacilityGetList(shop_id, page) {
  return await apiGetListLoop((_page) => {
    return api_facility.getShopList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, _page);
  }, page, 10)
}
export function apiShopFacilitySearch(conditions) {
  return api_facility.searchShop(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export function apiShopFacilityCreate(facility) {
  return api_facility.createShop(config.api, storeAuth.storeGetSelectedStaffId(), facility);
}
export function apiShopFacilityUpdate(facility) {
  return api_facility.updateShop(config.api, storeAuth.storeGetSelectedStaffId(), facility);
}

// 掲示板
export async function apiForumList(page) {
  return await apiGetListLoop((_page) => {
    return api_forum.getList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export async function apiForumPenddingsList(page) {
  return await apiGetListLoop((_page) => {
    return api_forum.getPenddingsList(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiForumPost(post, tags, shared_files, attached_files) {
  return api_forum.post(config.api, storeAuth.storeGetSelectedStaffId(), post, tags, shared_files, attached_files);
}
export function apiForumUpdate(post) {
  return api_forum.update(config.api, storeAuth.storeGetSelectedStaffId(), post);
}
export function apiForumTagAdd(post_key, tag_text) {
  return api_forum.addTag(config.api, storeAuth.storeGetSelectedStaffId(), post_key, tag_text);
}
export function apiForumTagDelete(post_key, tag_text) {
  return api_forum.removeTag(config.api, storeAuth.storeGetSelectedStaffId(), post_key, tag_text);
}
export function apiForumAttachmentAdd(post_key, shared_files, attached_files) {
  return api_forum.attachmentAdd(config.api, storeAuth.storeGetSelectedStaffId(), post_key, shared_files, attached_files);
}
export function apiForumAttachmentDelete(post_key, file_id) {
  return api_forum.attachmentDelete(config.api, storeAuth.storeGetSelectedStaffId(), post_key, file_id);
}
export function apiForumAttachmentEyecatchAdd(post_key, shared_files, attached_files) {
  return api_forum.attachmentEyecatchAdd(config.api, storeAuth.storeGetSelectedStaffId(), post_key, shared_files, attached_files);
}
export function apiForumAttachmentPreview(post_key, file_id, original = 1) {
  return api_forum.previewFile(config.api, storeAuth.storeGetSelectedStaffId(), post_key, file_id, original);
}
export function apiForumMessageAddReaction(post_key, emoji) {
  return api_forum.addReaction(config.api, storeAuth.storeGetSelectedStaffId(), post_key, emoji);
}
export function apiForumMessageRemoveReaction(post_key, emoji) {
  return api_forum.removeReaction(config.api, storeAuth.storeGetSelectedStaffId(), post_key, emoji);
}

// チャット
export function apiChatGetRoomList(shop_id, page = 1) {
  return api_chat.getRoomList(config.api, storeAuth.storeGetSelectedStaffId(), shop_id, page);
}
export function apiChatCreateRoom(room, members) {
  return api_chat.createRoom(config.api, storeAuth.storeGetSelectedStaffId(), room, members);
}
export function apiChatUpdateRoom(room) {
  return api_chat.updateRoom(config.api, storeAuth.storeGetSelectedStaffId(), room, 0);
}
export function apiChatDeleteRoom(room) {
  return api_chat.updateRoom(config.api, storeAuth.storeGetSelectedStaffId(), room, 1);
}
export function apiChatRoomMemberAdd(room_id, member_id) {
  return api_chat.roomMemberAdd(config.api, storeAuth.storeGetSelectedStaffId(), room_id, member_id);
}
export function apiChatRoomMemberDelete(room_id, member_id) {
  return api_chat.roomMemberDelete(config.api, storeAuth.storeGetSelectedStaffId(), room_id, member_id);
}
export function apiChatGetMessageList(room_id, page = 1) {
  return api_chat.getMessageList(config.api, storeAuth.storeGetSelectedStaffId(), room_id, page);
}
export function apiChatGetUnreadMessage(shop_id) {
  return api_chat.getMessageUnreadCount(config.api, storeAuth.storeGetSelectedStaffId(), shop_id);
}
export function apiChatSendMessage(room_id, post_text, mentions, url_links, attached_files, shared_files) {
  return api_chat.sendMessage(config.api, storeAuth.storeGetSelectedStaffId(), room_id, post_text, mentions, url_links, attached_files, shared_files);
}
export function apiChatMessageUpdate(room_id, chat_key, post_text, mentions) {
  return api_chat.updateMessage(config.api, storeAuth.storeGetSelectedStaffId(), room_id, chat_key, post_text, mentions, 0);
}
export function apiChatMessageDelete(room_id, chat_key, post_text, mentions) {
  return api_chat.updateMessage(config.api, storeAuth.storeGetSelectedStaffId(), room_id, chat_key, post_text, mentions, 1);
}
export function apiChatMessagePreview(room_id, file_id, original = 1) {
  return api_chat.previewFile(config.api, storeAuth.storeGetSelectedStaffId(), room_id, file_id, original);
}
export function apiChatMessageAddReaction(chat_key, emoji) {
  return api_chat.addReaction(config.api, storeAuth.storeGetSelectedStaffId(), chat_key, emoji);
}
export function apiChatMessageRemoveReaction(chat_key, emoji) {
  return api_chat.removeReaction(config.api, storeAuth.storeGetSelectedStaffId(), chat_key, emoji);
}

// ファイル管理
export function apiFileGetDir(dir_id, with_pending = 0) {
  return api_file.getDirInfo(config.api, storeAuth.storeGetSelectedStaffId(true), dir_id, with_pending);
}
export function apiFileGetTrashboxInfo() {
  return api_file.getTrashboxInfo(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiFileCreateDir(dir) {
  return api_file.createDir(config.api, storeAuth.storeGetSelectedStaffId(), dir);
}
export function apiFileUpdateDir(dir) {
  return api_file.updateDir(config.api, storeAuth.storeGetSelectedStaffId(), dir);
}
export function apiFileCreateFile(file, attached_files) {
  return api_file.createFile(config.api, storeAuth.storeGetSelectedStaffId(), file, attached_files);
}
export function apiFileUpdateFile(file) {
  return api_file.updateFile(config.api, storeAuth.storeGetSelectedStaffId(), file);
}
export function apiShareFilePreview(file_id, original = 1) {
  return api_file.preview(config.api, storeAuth.storeGetSelectedStaffId(), file_id, original);
}
export function apiFileUploadSplit(file, attached_files) {
  return api_file.uploadSplit(config.api, storeAuth.storeGetSelectedStaffId(), file, attached_files);
}
export function apiFileUploadUnify(file) {
  return api_file.uploadUnify(config.api, storeAuth.storeGetSelectedStaffId(), file);
}
export function apiFileGetPresignedUrl(file_id, specific_shop_id, expiresin = 60) {
  if (!specific_shop_id) {
    specific_shop_id = storeAuth.storeGetKeyAsSelectedShop()
  }
  return api_file.getPresignedUrl(config.api, storeAuth.storeGetSelectedStaffId(), file_id, specific_shop_id, expiresin);
}
export function apiFileSearch(conditions) {
  return api_file.fileSearch(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
// お問い合わせ
export function apiContactFormExec(contact, attached_files) {
  return api_contact_form.contact(config.api, storeAuth.storeGetSelectedStaffId(), contact, attached_files);
}

// 通知
export async function apiNotificationGetStats(conditions = {}) {
  return api_notification.getStats(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}
export async function apiNotificationSearch(conditions) {
  return api_notification.search(config.api, storeAuth.storeGetSelectedStaffId(), conditions);
}

export async function apiNotificationGetList(page, max = 5) {
  let dest_id = [
    storeAuth.storeGetAccountId(),
    storeAuth.storeGetKeyAsSelectedAssociate(),
    storeAuth.storeGetKeyAsSelectedStaff(),
    storeAuth.storeGetKeyAsSelectedCompany(),
    storeAuth.storeGetKeyAsSelectedBrand(),
    storeAuth.storeGetKeyAsSelectedShop(),
  ]
  dest_id = dest_id.filter((v) => v != null)
  if (page) {
    return await api_notification.getList(config.api, storeAuth.storeGetSelectedStaffId(), dest_id, page);
  } else {
    return await apiGetListLoop((_page) => {
      return api_notification.getList(config.api, storeAuth.storeGetSelectedStaffId(), dest_id, _page);
    }, page, max)
  }
}
export async function apiNotificationGetBarList() {
  return api_notification.getListForBar(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiNotificationCreate(notification) {
  return api_notification.create(config.api, storeAuth.storeGetSelectedStaffId(), notification);
}
export function apiNotificationDownloadDataFile(service_type, file_id) {
  return api_notification.downloadDataFile(config.api, storeAuth.storeGetSelectedStaffId(), service_type, file_id);
}

// 一括アップロード
export function apiBulkUpload(service_cd, attached_files) {
  return api_upload.bulkUpload(config.api, storeAuth.storeGetSelectedStaffId(), service_cd, attached_files);
}
export function apiBulkUploadDownloadFormat(format_type) {
  return api_upload.downloadBulkUploadFormat(config.api, storeAuth.storeGetSelectedStaffId(), format_type);
}

// フランチャイズ
export async function apiFcGetFranchiserRights(page) {
  return await apiGetListLoop((_page) => {
    return api_franchise.getFranchiserRights(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export async function apiFcGetFranchiseeRights(page) {
  return await apiGetListLoop((_page) => {
    return api_franchise.getFranchiseeRights(config.api, storeAuth.storeGetSelectedStaffId(), _page);
  }, page, 10)
}
export function apiFcUpdateRights(rights) {
  return api_franchise.updateRights(config.api, storeAuth.storeGetSelectedStaffId(), rights);
}
export async function apiFcShopList(conditions = {}, page) {
  return await apiGetListLoop((_page) => {
    return api_franchise.shopList(config.api, storeAuth.storeGetSelectedStaffId(), conditions, _page);
  }, page, 10)
}
export async function apiFcShopFranchisingList(conditions = {}, page) {
  return await apiGetListLoop((_page) => {
    return api_franchise.shopFranchisingList(config.api, storeAuth.storeGetSelectedStaffId(), conditions, _page);
  }, page, 10)
}

export default {
  // アソシエイツ
  apiAssociateGetList,
  apiAssociateUpdate,
  apiAssociateGetInfo,
  apiEmployeeProfileGetInfo,
  apiAssociateJoinShops,
  apiAssociateExitShops,

  // 企業
  apiCompanyGetInfo,
  apiCompanyGetList,
  apiCompanyUpdate,
  apiCompanyGenerateCode,

  // 従業員
  apiEmployeeGetList,
  apiEmployeeSearch,
  apiEmployeeGetPrevList,
  apiEmployeeCreate,
  apiEmployeeUpdate,
  apiEmployeeGetMyShopList,
  apiEmployeeSearchMyShop,
  apiEmployeeGetMyShopPreList,

  // スタッフグループ
  apiStaffGroupGetOwnList,
  apiStaffGroupGetList,
  apiStaffGroupCreate,
  apiStaffGroupUpdate,
  apiStaffGroupEmployeeList,

  // ロール
  apiGetRoleList,
  apiRoleCreate,
  apiRoleUpdate,

  // ポリシー
  apiGetPolicyList,

  // サービス
  apiServiceList,

  // 店舗
  apiShopGetOwnList,
  apiShopGetList,
  apiShopSearch,
  apiShopCreate,
  apiShopUpdate,
  apiShopAddArea,
  apiShopRemoveArea,
  apiShopUpdateTimes,
  apiShopGetStaffList,
  apiShopGetCalendar,
  apiShopStatusVManage,
  apiShopCreateVManage,
  apiShopUpdateVManage,
  apiShopUpdateIPAddress,
  apiShopIPAddressList,
  apiShopAccountCreate,
  apiShopAccountUpdate,

  // 店舗休業
  apiShopGetHolidayList,
  apiShopAddHoliday,
  apiShopRemoveHoliday,

  // 店舗スタッフ
  apiShopStaffRoleAdd,
  apiShopStaffRoleDelete,

  // スタッフ
  apiStaffPenddingList,
  apiStaffApprove,
  apiStaffUpdate,

  // ブランド
  apiBrandGetList,
  apiBrandCreate,
  apiBrandUpdate,
  apiBrandGenerateCode,

  // エリア
  apiGetAreaList,
  apiAreaCreate,
  apiAreaUpdate,
  apiAreaGenerateCode,
  apiAreaGetAssociates,
  apiAreaAddAssociates,
  apiAreaRemoveAssociates,
  apiAreaUpdateAssociate,

  // ポジション
  apiGetPositionList,
  apiPositionCreate,
  apiPositionUpdate,

  // 設備
  apiFacilityGetList,
  apiFacilitySearch,
  apiFacilityCreate,
  apiFacilityUpdate,

  // 店舗設備
  apiShopFacilityGetList,
  apiShopFacilitySearch,
  apiShopFacilityCreate,
  apiShopFacilityUpdate,

  // 掲示板
  apiForumList,
  apiForumPenddingsList,
  apiForumPost,
  apiForumUpdate,
  apiForumTagAdd,
  apiForumTagDelete,
  apiForumAttachmentAdd,
  apiForumAttachmentDelete,
  apiForumAttachmentEyecatchAdd,
  apiForumAttachmentPreview,
  apiForumMessageAddReaction,
  apiForumMessageRemoveReaction,

  // チャット
  apiChatGetRoomList,
  apiChatCreateRoom,
  apiChatUpdateRoom,
  apiChatDeleteRoom,
  apiChatRoomMemberAdd,
  apiChatRoomMemberDelete,
  apiChatGetMessageList,
  apiChatGetUnreadMessage,
  apiChatSendMessage,
  apiChatMessageUpdate,
  apiChatMessageDelete,
  apiChatMessagePreview,
  apiChatMessageAddReaction,
  apiChatMessageRemoveReaction,

  // ファイル管理
  apiFileGetDir,
  apiFileGetTrashboxInfo,
  apiFileCreateDir,
  apiFileUpdateDir,
  apiFileCreateFile,
  apiFileUpdateFile,
  apiShareFilePreview,
  apiFileUploadSplit,
  apiFileUploadUnify,

  // お問い合わせ
  apiContactFormExec,

  // 通知
  apiNotificationGetStats,
  apiNotificationSearch,

  apiNotificationGetList,
  apiNotificationGetBarList,
  apiNotificationCreate,
  apiNotificationDownloadDataFile,

  // 一括アップロード
  apiBulkUpload,
  apiBulkUploadDownloadFormat,

  // フランチャイズ
  apiFcGetFranchiserRights,
  apiFcGetFranchiseeRights,
  apiFcUpdateRights,
  apiFcShopList,
  apiFcShopFranchisingList,
}