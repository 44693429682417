import { useStoreSystem } from '@/store/system';
import { useStoreRouter } from '@/store/router';
const store = useStoreSystem();
const storeRouter = useStoreRouter();

// 表示モード
export function storeSetDisplayMode(display_mode) {
    store.display_mode = display_mode
}
export function storeGetDisplayMode() {
    return store.display_mode
}

// 通知バー
export function storeSetBeforeNoticeBarKey(notice_key) {
    store.before_notice_bar_key = notice_key
}
export function storeGetBeforeNoticeBarKey() {
    return store.before_notice_bar_key
}

// 本日のタスク表示制御
export function storeSetTodayTaskDisplaySetting(display_setting) {
    store.todaytask_display_setting = display_setting
}
export function storeGetTodayTaskDisplaySetting() {
    return store.todaytask_display_setting;
}

// ルーティング情報
export function storeSetRoute(route) {
    storeRouter.route = route
}
export function storeGetRoute() {
    return storeRouter.route;
}
export function storeSetRouter(router) {
    storeRouter.router = router
}
export function storeGetRouter() {
    return storeRouter.router;
}

export default {
    storeSetDisplayMode,
    storeGetDisplayMode,
    storeSetBeforeNoticeBarKey,
    storeGetBeforeNoticeBarKey,
    storeSetTodayTaskDisplaySetting,
    storeGetTodayTaskDisplaySetting,

    storeSetRoute,
    storeGetRoute,
    storeSetRouter,
    storeGetRouter,
}