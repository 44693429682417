import api_common from "@/helpers/api_common.js";

export default {

    // V-Manage利用申し込み情報取得 SELECT
    getRequestInfo(apiconf, staff_id, company_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "vmanage/request/list",
            query: { staff_id: staff_id, company_id: company_id },
        })
    },

    // V-Manage追加SU申し込み情報を取得 SELECT
    getAddSetupRequestInfo(apiconf, staff_id, company_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "vmanage/add_setup_request/list",
            query: { staff_id: staff_id, company_id: company_id },
        })
    },

    // V-Manage利用申し込み INSERT（申し込み情報は、Admin画面から新規登録に仕様変更のため現在は当APIは使用していません）
    // execRequest(apiconf, staff_id, request) {
    //     return api_common.callPostApi({
    //         conf: apiconf,
    //         path: "vmanage/request",
    //         query: { staff_id: staff_id, request: request },
    //     })
    // },
    
    // V-Manage利用申し込み
    execRequest(apiconf, staff_id, request) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "vmanage/request/update",
            query: { staff_id: staff_id, request: request },
        })
    },
    // V-Manage追加SU利用申し込み
    execAddSetupRequest(apiconf, staff_id, request) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "vmanage/add_setup_request/update",
            query: { staff_id: staff_id, request: request },
        })
    },
}