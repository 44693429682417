export default
    [
        {
            path: '/master',
            component: () => import('@/components/portal/Index'),
            children: [
                {
                    path: 'company',
                    name: 'master-company',
                    component: () => import('@/components/portal/master/company/IndexCompany'),
                    meta: { title: '企業', policy: { function_cd: "company", crud: ['c', 'r', 'u', 'd'], is_sp: false, fc_owner: false, shop_ip_limited: true } },
                },
                {
                    path: 'brand',
                    name: 'master-brand',
                    component: () => import('@/components/portal/master/brand/IndexBrand'),
                    meta: { title: 'ブランド', policy: { function_cd: "brand", crud: ['c', 'r', 'u', 'd'], is_sp: false, shop_ip_limited: true } },
                },
                {
                    path: 'area',
                    name: 'master-area',
                    component: () => import('@/components/portal/master/area/IndexArea'),
                    meta: { title: 'エリア', policy: { function_cd: "area", crud: ['c', 'r', 'u', 'd'], is_sp: false, fc_owner: false, shop_ip_limited: true } },
                },
                {
                    path: 'position',
                    name: 'master-position',
                    component: () => import('@/components/portal/master/position/IndexPosition'),
                    meta: { title: 'ポジション', policy: { function_cd: "position", crud: ['c', 'r', 'u', 'd'], is_sp: false, fc_owner: false, shop_ip_limited: true } },
                },
                {
                    path: 'shop',
                    name: 'master-shop',
                    component: () => import('@/components/portal/master/shop/IndexShop'),
                    meta: { title: '店舗', policy: { function_cd: "shop", crud: ['c', 'r', 'u', 'd'], is_sp: false, shop_ip_limited: true } },
                },
                {
                    path: 'facility',
                    name: 'master-facility',
                    component: () => import('@/components/portal/master/facility/IndexFacility'),
                    meta: { title: '設備', policy: { function_cd: "facility", crud: ['c', 'r', 'u', 'd'], is_sp: false, fc_owner: false, shop_ip_limited: true } },
                },
                {
                    path: 'employee',
                    name: 'master-employee',
                    component: () => import('@/components/portal/master/employee/IndexEmployee'),
                    meta: { title: '従業員', policy: { function_cd: "employee", crud: ['c', 'r', 'u', 'd'], is_sp: false, forced_portal: 'admin', shop_ip_limited: true } },
                },
                {
                    path: 'shop/employee',
                    name: 'master-shop-employee',
                    component: () => import('@/components/portal/master/shop_employee/IndexShopEmployee'),
                    meta: { title: '店舗従業員', policy: { function_cd: "employee", crud: ['r', 'u'], is_sp: false, forced_portal: 'manager', shop_ip_limited: true } },
                },
                {
                    path: 'role',
                    name: 'master-role',
                    component: () => import('@/components/portal/master/role/IndexRole'),
                    meta: { title: 'ロール', policy: { function_cd: "role", crud: ['c', 'r', 'u', 'd'], is_sp: false, fc_owner: false, shop_ip_limited: true } },
                },
            ]
        },
    ]

