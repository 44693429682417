import RouteHome from './home'
import RouteMaster from './master'
import RouteForum from './forum'
import RouteFile from './file'
import RouteOthers from './others'
import RouteAdmin from './admin'
import RouteRedirect from './redirect'

export default
    [
        ...RouteHome,
        ...RouteMaster,
        ...RouteRedirect,
        ...RouteForum,
        ...RouteFile,
        ...RouteOthers,
        ...RouteAdmin,
    ]

