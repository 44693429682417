export default {
    api: {
        baseurl: "https://api.eatery-portal.com/",
        version: "api/v0.1"
    },

    application: "eateryportal",    // iOS/Androidアプリ名

    expired_sec: 60 * 60 * 12,   // 仮タイムアウト（12時間）

    profile_resync_sec: 60,     // プロファイル更新状態確認周期（秒）
}