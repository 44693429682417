<template>
  <div>
    <div v-if="isSuccess" class="message info-message py-3 mb-6" v-html="success"></div>
    <div v-if="isErrorMessage" class="message error-message py-3 mb-6" v-html="error"></div>

    <!-- 特定コード表示 -->
    <div v-if="UsernameExistsException" class="message error-message mb-6">
      <div class="font-weight-bold">
        このメールアドレスは既に登録されています。
      </div>
      <div class="mt-1">
        <div>
          もしパスワードをお忘れの場合は、
          <a
            class="error-message-inner-link font-weight-bold text-decoration-underline"
            @click="clickMove('forget_password')"
            >パスワードを忘れた方</a
          >をクリックしてください。
        </div>
        <div>
          サインインするには
          <a
            class="error-message-inner-link font-weight-bold text-decoration-underline"
            @click="clickMove('signin')"
            >こちら</a
          >をクリックしてください。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { success: String, error: String, moveFunc: Function },
  setup() {
    const { pageMove } = require("@/helpers/common/common_util");
    return {
      pageMove,
    };
  },
  data: () => ({}),
  methods: {
    clickMove(code) {
      this.moveFunc ? this.moveFunc(code) : this.pageMove(code);
    },
  },
  computed: {
    isSuccess() {
      if (this.success && this.success != "") return true;
      return false;
    },
    isErrorMessage() {
      if (this.error && this.error != "") {
        if (this.UsernameExistsException) return false;
        return true;
      }
      return false;
    },
    UsernameExistsException() {
      return this.error && this.error == "UsernameExistsException";
    },
  },
};
</script>
<style scoped>
.message {
  padding: 8px 16px 8px 16px;
  gap: 0px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
}
.info-message {
  background: #ebf1ff;
  border: 1px solid #1b6dbe;
}
.error-message {
  background: #ffedea;
  border: 1px solid #d32b2b;
}
.error-message,
.error-message-inner-link {
  color: var(--alias-token-color-text-negative-dark);
}
</style>
