import config from "@/configs/common";
import Twemoji from "twemoji";
import storeAuth from "@/helpers/store/storeAuthHelper"
import commonApi from "@/helpers/common/common_api"
import { getUserName, getJoinUserName } from "@/helpers/auth/auth_util"
import {
  startExecute, finishExecute, getRouteName, dataSort, getUploadErrorMessage,
  previewAttachedWithShareFile, compressUploadImage,
  formatDateStrByUnixtime, checkAttachedFiles,
} from "@/helpers/common/common_util"
import { previewParallel } from "@/helpers/api_common"
import { errorLog } from "@/helpers/common/datadog"

// スタッフ一覧取得
export async function readMembers() {
  let members = []
  const results = await commonApi.apiAssociateGetList()
  for (const item of results.data) {
    members.push({
      account_id: item.account_id,
      member_id: item.associate_id,
      user_name: getJoinUserName(item),
      is_shop_account: !item.signin_shop_id ? false : true,
    })
  }
  return members
}

// ルーム一覧
export async function chatRoomsRead(self) {
  self.params.loading = false;
  let shops = []
  let groups = []
  let directs = []
  let group_employee = null
  let group_permanents = []
  try {
    const results = await commonApi.apiChatGetRoomList(self.params.shoproom_only ? storeAuth.storeGetKeyAsSelectedShop() : null)
    for (const item of results.data) {
      item.members.map(v => {
        v.user_name = getJoinUserName(v);
        v.is_shop_account = !v.for_shop_signin || v.for_shop_signin == 1 ? false : true;
      })
      if (item.room.employee_all_flg != 9) {
        if (item.room.permanent_flg == 9) {
          group_employee = item
        } else {
          group_permanents.push(item)
        }
      } else if (item.room.shop_id) {
        shops.push(item)
      } else if (isDirectMessage(item)) {
        // DM相手がアカウント削除済か判定
        item.deleted_user_dm = item.members.length == 1 || item.members.find(v => v.associate_status == 1)
        directs.push(item)
      } else {
        groups.push(item)
      }
    }
  } catch (err) {
    errorLog(getRouteName(), "readRooms", err);
    self.params.error = "読み込みに失敗しました。"
  }

  shops.sort((a, b) => {
    if (a.room.room_name > b.room.room_name) return 1
    if (a.room.room_name < b.room.room_name) return -1
    return 0
  })
  directs.sort((a, b) => {
    if (a.room.room_name > b.room.room_name) return 1
    if (a.room.room_name < b.room.room_name) return -1
    return 0
  })
  groups.sort((a, b) => {
    if (a.room.room_name > b.room.room_name) return 1
    if (a.room.room_name < b.room.room_name) return -1
    return 0
  })
  if (group_permanents.length > 0) {
    group_permanents.sort((a, b) => a.room.permanent_flg < b.room.permanent_flg ? -1 : 1)
    groups.unshift(...group_permanents)
  }
  if (group_employee) {
    groups.unshift(group_employee)
  }
  self.params.rooms.shops = shops;
  self.params.rooms.groups = groups;
  self.params.rooms.directs = directs;
  filterRooms(self)
  self.params.loading = true;
}

export function filterRooms(self) {
  const associate_id = storeAuth.storeGetSelectedAssociate().associate_id
  let shops = self.params.rooms.shops
  let groups = self.params.rooms.groups
  let directs = self.params.rooms.directs
  if (self.params.filter_room) {
    shops = shops.filter(v => v.room.room_name.includes(self.params.filter_room))
    groups = groups.filter(v => v.room.room_name.includes(self.params.filter_room))
    directs = directs.filter(v => v.room.room_name.includes(self.params.filter_room))
  }
  // ショップ、グループ、DMの未読のルームを抽出する
  let unread_list = [...shops.filter((v) => v.unread), ...groups.filter((v) => v.unread), ...directs.filter((v) => v.unread)]
  unread_list.forEach((v) => {
    if (v.room.room_type == 1) {
      v.members.forEach((member) => {
        if (member.member_id !== associate_id) {
          v.room.room_name = member.user_name
        }
      })
    }
  })
  self.params.filter_shops = shops
  self.params.filter_groups = groups
  self.params.filter_directs = directs
  self.params.filter_unread = unread_list
}

// ルーム
export async function execCreateChatRoom(self) {
  if (!self.room_name || self.saving) return;
  startExecute(self);
  try {
    self.saving = true;
    self.params.success = ""
    self.params.error = ""
    const result = await chatRoomCreate(self.room_name);
    await createRoomAfter(self, result)
    self.params.success = "ルーム " + self.room_name + " を作成しました。";
    self.modal_create_room = false;
  } catch (err) {
    errorLog(getRouteName(), "createChatRoom", err);
    self.params.error = "作成に失敗しました。";
    self.modal_create_room = false;
  }
  finishExecute(self);
  self.saving = false;
}
export async function chatRoomCreate(room_name, room_members = [], room_type = ChatRoomType.group) {
  const room = {
    room_name: room_name,
    room_type: room_type,
    send_notice: ChatSendNotice.true,
  };
  const members = [];
  for (const member of room_members) {
    members.push(member);
  }
  if (!members.includes(storeAuth.storeGetKeyAsSelectedAssociate())) {
    members.push(storeAuth.storeGetKeyAsSelectedAssociate())
  }
  return await commonApi.apiChatCreateRoom(room, members)
}
export function getRoomName(item) {
  if (item) return isDirectMessage(item) ? getDMMemberName(item) : item.room.room_name
}
export function getGroupOrDMText(item) {
  if (item) return isDirectMessage(item) ? "ダイレクトメッセージ" : "グループ"
}

// ダイレクトメッセージ
export async function execCreateChatDM(self, staff) {
  startExecute(self);
  try {
    self.params.success = ""
    self.params.error = ""
    const result = await chatRoomCreate(staff.user_name, [staff.member_id], ChatRoomType.direct);
    await createRoomAfter(self, result)
    self.params.success = staff.user_name + "とのダイレクトメッセージを作成しました。";
  } catch (err) {
    errorLog(getRouteName(), "execCreateChatDM", err);
    self.params.error = "作成に失敗しました。";
  }
  finishExecute(self);
}
export function getDMMember(item) {
  return item.members.find(v => v.member_id != storeAuth.storeGetKeyAsSelectedAssociate()) ?? item.members.find(v => v.member_id == storeAuth.storeGetKeyAsSelectedAssociate())
}
export function getDMMemberAccountId(item) {
  const dm = getDMMember(item)
  return dm ? dm.account_id : null
}
export function getDMMemberName(item) {
  const dm = getDMMember(item)
  if (!dm) return ""
  if (dm.associate_status == 1) return dm.user_name + "（削除済み）"
  return dm.user_name
}
export function isDirectMessage(item) {
  return item.room.room_type == ChatRoomType.direct
}

// ルーム&DM作成後処理
async function createRoomAfter(self, result) {
  await chatRoomsRead(self);
  let room = null
  if (self.changeRoom && result && result.ids && result.ids.room_id) {
    const room_id = result.ids.room_id
    room = self.params.rooms.shops.find(v => v.room.room_id == room_id)
    if (!room) room = self.params.rooms.groups.find(v => v.room.room_id == room_id)
    if (!room) room = self.params.rooms.directs.find(v => v.room.room_id == room_id)
  }
  self.changeRoom(room)
}

// ルーム名変更
export async function updateChatRoomName(item, room_name) {
  const room = {
    room_id: item.room.room_id,
    room_type: item.room.room_type,
    room_name: room_name,
    send_notice: ChatSendNotice.false,
  };
  await commonApi.apiChatUpdateRoom(room)
  item.room.room_name = room_name;
}

// ルームメンバー
export function getChatRoomMemberIcons(members) {
  let results = []
  for (let i = 0; i < 3 && i < members.length; i++) {
    results.push(members[i])
  }
  return results
}
export async function addChatRoomMembers(item, members) {
  for (const member of members) {
    await commonApi.apiChatRoomMemberAdd(item.room.room_id, member)
  }
}
export async function deleteChatRoomMembers(item, members) {
  for (const member of members) {
    await commonApi.apiChatRoomMemberDelete(item.room.room_id, member)
  }
}

// ルーム削除
export async function deleteChatRoom(item) {
  const room = {
    room_id: item.room.room_id,
    room_type: item.room.room_type,
    room_name: item.room.room_name,
    send_notice: ChatSendNotice.false,
  };
  await commonApi.apiChatDeleteRoom(room)
}

// メッセージ読み込み
export async function chatMessagesRead(self, item, is_old = false, is_init = false) {
  try {
    let page = 1
    if (is_old && item.current_page) {
      page = item.current_page + 1;
    }
    const results = await commonApi.apiChatGetMessageList(item.room.room_id, page)
    item.max_page = results.max_page;
    item.current_page = results.current_page;
    item.totalcount = results.totalcount;
    item.is_oldest =
      results.datacount < results.datalimit;
    if (results.datacount) {
      setChatMessages(item, results.data, is_init)
      filterMessage(item);
    }
  } catch (err) {
    errorLog(getRouteName(), "chatMessagesRead", err);
    self.params.error = "読み込みに失敗しました。"
  }
}

// メッセージポーリング
export function startPolling(self) {
  if (!self.params.interval && self.params.viewroom) {
    self.params.interval = setInterval(() => {
      if (self.params.viewroom) {
        chatMessagesRead(self, self.params.viewroom)
      }
    }, config.chat_reload_msec);
  }
}
export async function deletePolling(self) {
  if (self.params.interval) {
    clearInterval(self.params.interval);
    self.params.interval = null;
  }
}

// メッセージ追加
export function setChatMessages(item, messages, is_init = false) {
  if (!item.messages) item.messages = []
  for (const data of messages) {
    if (data.message.created_by.toLowerCase() == "v-manage") {
      data.username = "V-Manageからのお知らせ"
      data.userimage = require("@/assets/chat_v-manage.png");
    } else {
      data.username = getJoinUserName(data.message)
    }
    data.message.created_at = new Date(data.message.created_date).getTime() / 1000
    data.ymd = dateAtToYMD(data.message.created_at);
    data.time = dateAtToHM(data.message.created_at)
    data.editable = data.message.created_by == storeAuth.storeGetKeyAsSelectedAssociate()
    let message = item.messages.find(v => v.ymd == data.ymd)
    if (message) {
      let _item = message.items.find(v => v.message.chat_key == data.message.chat_key)
      if (!message.items.find(v => v.message.chat_key == data.message.chat_key)) {
        message.items.push(data)
        message.items.sort((a, b) => a.message.created_at - b.message.created_at)
      } else {
        // MEMO：リアクションだけ変更あり
        _item.reactions = data.reactions
      }
    } else {
      item.messages.push({
        ymd: data.ymd,
        items: [data]
      })
    }
  }
  dataSort(item.messages, "ymd", true)
  readAttachedFiles(item, is_init)
}

// メッセージ絞込
export async function filterMessage(item) {
  if (item.messages) {
    // item.view_messages = self.copyJson(item.messages)
    item.view_messages = item.messages
    self.drawcnt++
  }
}

// 添付ファイル
export async function readAttachedFiles(item, is_init = false) {
  let previews = [];
  for (let msg of item.messages) {
    for (let data of msg.items) {
      for (let file of data.attachments) {
        file.is_init = is_init
        if (!file.read_attached) {
          previews.push({
            method: previewAttachedWithShareFile,
            params: [file,
              commonApi.apiChatMessagePreview,
              data.message.room_id,
              true],
            callback: callback,
          });
        }
      }
    }
  }
  if (previews.length > 0) previewParallel(previews);
  async function callback() {
    await filterMessage(item)
  }
}

// チャット投稿
export async function sendChat(self, item) {
  self.params.error = "";
  if (!self.post_text || self.saving) return;

  // 添付ファイルチェック
  self.params.error = checkAttachedFiles(self.attachments);
  if (self.params.error) return;

  startExecute(self);
  try {
    self.saving = true
    const mentions = [];
    let url_links = []
    let attached_files = [];
    let shared_files = [];
    for (const url of self.url_links) {
      url_links.push({ key: url.key, value: url.value })
    }
    for (const attachment of self.attachments) {
      if (attachment.file.file_id) {
        shared_files.push(attachment.file.file_id)
      } else {
        attached_files.push(await compressUploadImage(attachment.file))
      }
    }
    for (const member of self.confirm_mention_list) {
      mentions.push(member.member_id)
    }
    await commonApi.apiChatSendMessage(
      item.room.room_id,
      self.post_text,
      mentions,
      url_links,
      attached_files,
      shared_files
    )
    self.post_text = "";
    self.url_links = []
    self.attachments = []
    await chatMessagesRead(self, self.params.viewroom)
    messageScroll(self);
  } catch (err) {
    errorLog(getRouteName(), "sendChat", err);
    self.params.error = getUploadErrorMessage(err) ?? "送信に失敗しました。"
  }
  finishExecute(self);
  self.saving = false
}

// メッセージ削除
export async function deleteChatMessage(item, target) {
  await commonApi.apiChatMessageDelete(
    target.message.room_id,
    target.message.chat_key,
    target.message.post_text,
    target.message.mentions
  )
  let data = item.messages.find(v => v.ymd == target.ymd)
  data.items = data.items.filter(v => v.message.chat_key != target.message.chat_key)
  item.datacount--;
  item.totalcount--;
  filterMessage(item)
}

// リアクション
export function getTwemojiPath() {
  return "/twemoji/"
}
export function getReactionImg(unicode) {
  return Twemoji.parse(unicode, { base: getTwemojiPath() });
}
export function clickOpenReaction(item) {
  self.reaction_message = item;
}
export async function execChatReaction(self, item, emoji) {
  if (item.reactions.find(v => v.reaction_emoji == emoji && v.member_id == storeAuth.storeGetKeyAsSelectedAssociate())) {
    removeReaction()
  } else {
    addReaction()
  }
  self.drawcnt++
  async function addReaction() {
    try {
      item.reactions.push({
        name: getUserName(),
        reaction_emoji: emoji,
        member_id: storeAuth.storeGetKeyAsSelectedAssociate(),
        created_date: new Date(),
      })
      await commonApi.apiChatMessageAddReaction(item.message.chat_key, emoji)
    } catch (err) {
      errorLog(getRouteName(), "addReaction", err);
    }
  }
  async function removeReaction() {
    try {
      item.reactions = item.reactions.filter(v => !(v.reaction_emoji == emoji && v.member_id == storeAuth.storeGetKeyAsSelectedAssociate()))
      await commonApi.apiChatMessageRemoveReaction(item.message.chat_key, emoji)
    } catch (err) {
      errorLog(getRouteName(), "removeReaction", err);
    }
  }
}
export function getMessageReactions(item) {
  let reactions = []
  item.reactions.forEach(v => {
    let reaction = reactions.find(r => r.reaction == v.reaction_emoji)
    if (!reaction) {
      reaction = { reaction: v.reaction_emoji, members: [], created_time: new Date(v.created_date) }
      reactions.push(reaction)
    } else {
      const created_time = new Date(v.created_date)
      if (reaction.created_time > created_time) reaction.created_time = created_time
    }
    let member = {
      name: v.name ?? v.first_name + " " + v.last_name,
      member_id: v.member_id,
      is_self: v.member_id == storeAuth.storeGetKeyAsSelectedAssociate(),
    }
    if (v.member_id == storeAuth.storeGetKeyAsSelectedAssociate()) {
      member.is_self = true
      reaction.include_self = true
    }
    reaction.members.push(member)
  })
  dataSort(reactions, "created_time", true)
  return reactions
}

// スクロール制御
export function messageScroll(self, post_key) {
  if (post_key) { // eslint-disable-line
  } else {
    const scroller = document.querySelector("#scroll-body");
    scroller.scrollTo(0, 999999)
  }
}
export function loadedAttachedImage(self, file) {
  if (file.is_init) {
    const scroller = document.querySelector("#scroll-body");
    scroller.scrollTo(0, 999999)
    file.is_init = false
  }
}

// メンバー自身判定
export function isMemberSelf(member) {
  return member.member_id == storeAuth.storeGetKeyAsSelectedAssociate()
}
export function getMyMemberId() {
  return storeAuth.storeGetKeyAsSelectedAssociate()
}

// 時刻変換
function dateAtToHM(date) {
  return formatDateStrByUnixtime(date, "hh:mm", true);
}
function dateAtToYMD(date) {
  return formatDateStrByUnixtime(date, "YYYY/MM/DD", true);
}

// ルーム種別
export function isRoomShop(item) {
  return item.room.shop_id ? true : false
}

// アカウント削除済ユーザ判定
export function isDeletedAccount(item) {
  return item.message.associate_status == 1;
}

export default {

  // スタッフ一覧取得
  readMembers,

  // ルーム一覧
  chatRoomsRead,

  filterRooms,

  // ルーム
  execCreateChatRoom,
  chatRoomCreate,
  getRoomName,
  getGroupOrDMText,

  // ダイレクトメッセージ
  execCreateChatDM,
  getDMMember,
  getDMMemberAccountId,
  getDMMemberName,
  isDirectMessage,

  // ルーム名変更
  updateChatRoomName,

  // ルームメンバー
  getChatRoomMemberIcons,
  addChatRoomMembers,
  deleteChatRoomMembers,

  // ルーム削除
  deleteChatRoom,

  // メッセージ読み込み
  chatMessagesRead,

  // メッセージポーリング
  startPolling,
  deletePolling,

  // メッセージ追加
  setChatMessages,

  // メッセージ絞込
  filterMessage,

  // 添付ファイル
  readAttachedFiles,

  // チャット投稿
  sendChat,

  // メッセージ削除
  deleteChatMessage,

  // リアクション
  getTwemojiPath,
  getReactionImg,
  clickOpenReaction,
  execChatReaction,
  getMessageReactions,

  // スクロール制御
  messageScroll,
  loadedAttachedImage,

  // メンバー自身判定
  isMemberSelf,
  getMyMemberId,

  // ルーム種別
  isRoomShop,

  // アカウント削除済ユーザ判定
  isDeletedAccount,
}

// ルームタイプ
const ChatRoomType = {
  group: 0,   // グループ
  direct: 1,  // 個別
}

// 通知送信
const ChatSendNotice = {
  true: 0,    // 対象
  false: 1,   // 対象外
}