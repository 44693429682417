import { useStoreActivate } from '@/store/activate';
const store = useStoreActivate();


// アクティベート
export function storeSetActivateInviteCode(invite_id) {
    store.invite_id = invite_id;
}
export function storeSetActivateInviteStatus(status) {
    store.status = status;
}
export function storeSetActivateInviteError(error) {
    store.invite_error = error;
}
export function storeSetActivateInviteSuccess(success) {
    store.invite_success = success;
}
export function storeResetActivate() {
    store.invite_id = null
    store.status = {}
}
export function storeGetActivateInviteCode() {
    return store.invite_id
}
export function storeGetActivateInviteStatus() {
    return store.status
}
export function storeGetActivateInviteError() {
    return store.invite_error
}
export function storeGetActivateInviteSuccess() {
    return store.invite_success
}

// （外部認証用）利用規約同意状態
export function storeSetPortalTerms(is_portal_terms = false) {
    store.is_portal_terms = is_portal_terms;
}
export function storeIsPortalTerms() {
    return store.is_portal_terms
}

// 新規企業登録判定
export function storeSetIsNewCompanyCreate(is_new_company_create = false) {
    store.is_new_company_create = is_new_company_create;
}
export function storeGetIsNewCompanyCreate() {
    return store.is_new_company_create
}

export default {
    storeSetActivateInviteCode,
    storeSetActivateInviteStatus,
    storeSetActivateInviteError,
    storeSetActivateInviteSuccess,
    storeResetActivate,
    storeGetActivateInviteCode,
    storeGetActivateInviteStatus,
    storeGetActivateInviteError,
    storeGetActivateInviteSuccess,

    storeSetPortalTerms,
    storeIsPortalTerms,

    storeSetIsNewCompanyCreate,
    storeGetIsNewCompanyCreate,
}