import api_common from "@/helpers/api_common.js";

export default {

    // 招待コード確認
    applycheck(apiconf, invitation_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "invitation/applycheck",
            query: { invitation: { invitation_id: invitation_id } },
            error_results: true,
        })
    },

    // 招待コード作成
    create(apiconf, staff_id, invitation) {
        let query = {
            staff_id: staff_id,
            invitation: invitation,
        }
        return api_common.callPostApi({
            conf: apiconf,
            path: "invitation/create",
            query: query,
        })
    },

    // 招待リスト取得
    getList(apiconf, staff_id, company_id, page = 1) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "invitation/list",
            query: { staff_id: staff_id, company_id: company_id, page: page },
        })
    },

    // メール招待通知
    sendEmail(apiconf, staff_id, send_to, invitation_id, url) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "invitation/send/email",
            query: {
                staff_id: staff_id, invitation: {
                    send_to: send_to, invitation_id: invitation_id, url: url
                }
            },
        })
    },

    // 一括招待テンプレートダウンロード
    downloadTemplate(apiconf, staff_id) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "download/template/mail_invitation",
            query: {
                staff_id: staff_id,
            },
        })
    },

    // メール一括招待ファイルアップロード
    uploadExcelFile(apiconf, staff_id, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "upload/excel/mail_invitation",
            formdata: formdata,
        })
    },
}