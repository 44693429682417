import axios from "axios";
import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserAttribute,
    AuthenticationDetails,
} from "amazon-cognito-identity-js";
import {
    getCognitoPoolId, getCognitoAppClientId,
    getCognitoDomain, generateState,
    getCognitoExternalRedirectUri, getCognitoExternalClientId, getCognitoExternalSecret,
} from "@/helpers/auth/auth_util";
import storeAuth from "@/helpers/store/storeAuthHelper"

// ユーザプール取得
function _getUserPool() {
    return new CognitoUserPool({
        UserPoolId: getCognitoPoolId(),
        ClientId: getCognitoAppClientId(),
    });
}

// Cognitoサインイン
export function cognitoSignin(email, password) {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: _getUserPool(),
    });
    const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
            newPasswordRequired(userAttributes, requiredAttributes) {  // eslint-disable-line
                cognitoUser.completeNewPasswordChallenge(password, { 'name': email }, {
                    onSuccess: (result) => {
                        resolve(result);
                    },
                    onFailure: (err) => {
                        reject(err);
                    }
                });
            },
        });
    });
}

// Cognitoサインアウト
export function cognitoSignout() {
    let userPool = _getUserPool();
    return new Promise((resolve) => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser !== null) {
            cognitoUser.signOut();
        } else {
            const username = storeAuth.storeGetExternalUsername();
            if (username) {
                const externalCognitoUser = new CognitoUser({
                    Username: username,
                    Pool: _getUserPool(),
                });
                externalCognitoUser.signOut()
            }
        }
        resolve();
    });
}

// Cognitoサインアップ
export function cognitoSignup(email, password, attr) {
    let attributeList = [
        new CognitoUserAttribute({
            Name: "email",
            Value: email,
        }),
    ];
    Object.keys(attr).forEach(key => {
        attributeList.push(new CognitoUserAttribute({
            Name: key,
            Value: attr[key],
        }));
    });
    let userPool = _getUserPool();
    return new Promise((resolve, reject) => {
        userPool.signUp(
            email,
            password,
            attributeList,
            null,
            function (err, result) {
                if (err) {
                    reject(err)
                } else {
                    resolve(result)
                }
            }
        );
    });
}

// Cognitoサインアップ確認
export function cognitoConfirmSignup(email, code) {
    let userPool = _getUserPool();
    var cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(
            code,
            true,
            function (err, result) {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            }
        );
    });
}

// Cognito認証コード再送
export function cognitoResendConfirmationCode(email) {
    let userPool = _getUserPool();
    var cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode(
            function (err, result) {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            }
        );
    });
}

// Cognitoパスワード変更
export function cognitoChangePassword(old_password, new_password) {
    let userPool = _getUserPool();
    //TODO:アプリの場合、サインインしてないのでusernameが分からない
    //API側で取得してもらう方が無難か
    //というか、ID_TOKENとユーザの照らし合わせが必要な気がするのでパスワード変更はメール経由が無難かも
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();
        const authenticationDetails = new AuthenticationDetails({
            Username: cognitoUser.username,
            Password: old_password,
        });
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: () => {
                cognitoUser.changePassword(
                    old_password,
                    new_password,
                    function (err, result) {
                        if (!err && result == "SUCCESS") {
                            resolve(result);
                        } else {
                            reject(err);
                        }
                    }
                );
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}

// Cognitoパスワード再設定
export function cognitoForgetPassword(email) {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: _getUserPool(),
    });
    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
            inputVerificationCode: (data) => {
                resolve(data);
            },
        });
    });
}

// Cognitoパスワード再設定確認
export function cognitoConfirmPassword(email, code, password) {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: _getUserPool(),
    });
    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(
            code,
            password,
            {
                onSuccess: (result) => {
                    resolve(result);
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
    });
}

// Cognito外部ログイン
export function cognitoExternalSignin(type, signin_company_create = 0) {
    storeAuth.storeSetPrevSigninType(type.toLowerCase())
    storeAuth.storeSetSigninCompanyCreate(signin_company_create)
    let href = "";
    href += getCognitoDomain();
    href += "/oauth2/authorize";
    href += "?identity_provider=" + type;
    href += "&redirect_uri=" + getCognitoExternalRedirectUri();
    href += "&response_type=CODE";
    href += "&client_id=" + getCognitoExternalClientId();
    href += "&state=" + generateState();
    window.location = href;
}

// Cognito 外部OAuthトークン情報取得
export function cognitoExternalOAuthToken(code) {
    return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append("grant_type", "authorization_code");
        params.append("client_id", getCognitoExternalClientId());
        params.append("redirect_uri", getCognitoExternalRedirectUri());
        params.append("code", code);
        axios
            .post(getCognitoDomain() + "/oauth2/token", params, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization:
                        "Basic " +
                        window.btoa(
                            getCognitoExternalClientId() + ":" + getCognitoExternalSecret()
                        ),
                },
            })
            .then(function (results) {
                resolve(results.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

// Cognitoエラーメッセージ
export function cognitoErrorMessage(err, default_message, type = "email") {
    if (!err) {
        return "";
    }
    switch (err.code) {
        case "NotAuthorizedException":
            if (type == "id") {
                return "店舗IDまたはパスワードが不正です。";
            } else {
                return "メールアドレスまたはパスワードが不正です。";
            }
        case "UsernameExistsException":
            return "UsernameExistsException";
        case "LimitExceededException":
            return "実行制限を超えました。しばらくしてから再度実施してください。";
        case "CodeMismatchException":
            return "認証コードが不正です。";
        case "InvalidParameterException":
            return "入力パラメータが不正です。";
    }
    return default_message;
}

export default {
    cognitoSignin,
    cognitoSignout,
    cognitoSignup,
    cognitoConfirmSignup,
    cognitoResendConfirmationCode,
    cognitoForgetPassword,
    cognitoConfirmPassword,
    cognitoExternalSignin,
    cognitoExternalOAuthToken,
    cognitoErrorMessage,
}

