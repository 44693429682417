import service from "@/configs/service";
import isMobile from "ismobilejs";

export function getEnvironment() {
    if (window.location.host.includes("localhost")) {
        return "local"
    } else if (window.location.host.includes("imlab.cloud")) {
        return "mock";
    } else if (window.location.host.includes("eatery-portal.com") || window.location.host.includes("eatery-timemng.com")) {
        if (window.location.host.includes("rev.")) {
            // レビュー環境
            return "rev";
        }
        return "dev";
    } else {
        return "prod"
    }
}
export const isEnvLocal = () => {
    return getEnvironment() == "local";
}
export const isEnvMock = () => {
    return getEnvironment() == "mock";
}
export const isEnvDev = () => {
    return getEnvironment() == "dev";
}
export const isEnvRev = () => {
    return getEnvironment() == "rev";
}
export function isEnvProd() {
    return getEnvironment() == "prod";
}
export const isEnvStaging = () => {
    return (window.location.host.includes("stg.portal.infomart.co.jp") || window.location.host.includes("stg.v-manage.restartz.co.jp"))
}

// 起動サービス
export const getServiceType = () => {
    return service.service_type
}
export const getServiceTypePortal = () => {
    return "portal"
}
export const getServiceTypeTimemng = () => {
    return "timemanagement"
}
export const isServicePortal = () => {
    return getServiceType() == getServiceTypePortal()
}
export const isServiceTimemng = () => {
    return getServiceType() == getServiceTypeTimemng()
}

// ユーザエージェント判定
export const getUserAgent = () => {
    try {
        // MEMO：将来的にChrome等ではuserAgentが使えなくなり、userAgentDataを利用する必要がある模様
        return window.navigator.userAgent || window.navigator.userAgentData;
    } catch (err) {
        return ""
    }
}
export const isUserAgentPhone = () => {
    return isMobile(getUserAgent()).phone
}
export const isUserAgentTablet = () => {
    return isMobile(getUserAgent()).tablet
}
export const isUserAgentAny = () => {
    return isMobile(getUserAgent()).any
}

// ソースバージョン管理
export const getSourceVersion = () => {
    return 0.1
}

export default {
    getEnvironment,
    isEnvLocal,
    isEnvMock,
    isEnvDev,
    isEnvProd,
    isEnvStaging,

    // 起動サービス
    getServiceType,
    getServiceTypePortal,
    getServiceTypeTimemng,
    isServicePortal,
    isServiceTimemng,

    // ユーザエージェント判定
    getUserAgent,
    isUserAgentPhone,
    isUserAgentTablet,
    isUserAgentAny,

    // ソースバージョン管理
    getSourceVersion,
}