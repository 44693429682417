export default
    [
        {
            path: '/',
            component: () => import('@/components/portal/Index'),
            children: [
                {
                    path: 'menus',
                    name: 'others-menus',
                    component: () => import('@/components/portal/others/menus/IndexMenus'),
                    meta: { title: 'メニュー' },
                },
                {
                    path: 'account',
                    name: 'others-account',
                    component: () => import('@/components/portal/others/account/IndexAccount'),
                    meta: { title: 'アカウント' },
                },
                {
                    path: 'myapp',
                    name: 'others-myapp',
                    component: () => import('@/components/portal/others/myapp/IndexMyapp'),
                    meta: { title: 'すべてのサービス' },
                },
                {
                    path: 'linked_services',
                    name: 'others-linked-services',
                    component: () => import('@/components/portal/others/linked_services/IndexLinkedServices'),
                    meta: { title: '連携サービス' },
                },
                {
                    path: 'help',
                    name: 'others-help',
                    component: () => import('@/components/portal/others/help/IndexHelp'),
                    meta: { title: 'ヘルプセンター' },
                },
                {
                    path: 'help/contact',
                    name: 'others-contact',
                    component: () => import('@/components/portal/others/contact/IndexContact'),
                    meta: { title: 'お問い合わせフォーム' },
                },
                {
                    path: 'help/contact/complete',
                    name: 'others-contact-complete',
                    component: () => import('@/components/portal/others/contact/IndexContactComplete'),
                    meta: { title: 'お問い合わせ完了' },
                },
                {
                    path: 'help/startguide',
                    name: 'others-startguide',
                    component: () => import('@/components/portal/others/help/IndexStart'),
                    meta: { title: 'はじめての方' },
                },
                {
                    path: 'help/manual',
                    name: 'others-manual',
                    component: () => import('@/components/portal/others/help/IndexManual'),
                    meta: { title: 'マニュアル' },
                },
                {
                    path: 'help/faq',
                    name: 'others-faq',
                    component: () => import('@/components/portal/others/help/IndexFaq'),
                    meta: { title: 'FAQ' },
                },
                {
                    path: 'about',
                    name: 'others-about',
                    component: () => import('@/components/portal/others/about/IndexAbout'),
                    meta: { title: 'アプリについて' },
                },
                {
                    path: 'request/vmanage',
                    name: 'request-vmanage',
                    component: () => import('@/components/portal/others/request/IndexRequestVManage'),
                    meta: { title: 'V-Manage利用申し込み' },
                },
                {
                    path: 'request/add_setup_vmanage',
                    name: 'add-setup-request-vmanage',
                    component: () => import('@/components/portal/others/request/IndexAddSetupRequestVManage'),
                    meta: { title: 'V-Manage追加SU利用申し込み' },
                },
                {
                    path: 'settings/alert',
                    name: 'timemanagement-settings-alert',
                    component: () => import('@/components/portal/others/settings/alert/IndexSettingsAlert'),
                    meta: { title: 'プッシュ通知設定', icon: "mdi-cog", policy: { is_app: true } },
                },
            ]
        },
    ]

