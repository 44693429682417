
import api_common from "@/helpers/api_common.js";

// プロフィール取得
export function getUserProfile(apiconf) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/profile",
            query: {},
            checkerror: false,
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

export function getUserStaffGroup(apiconf, staff_id, conditions) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "shop/staff/detail",
            query: { staff_id: staff_id, conditions: conditions },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function getUserStaffRoles(apiconf, staff_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "staff/roles",
            query: { staff_id: staff_id },
            checkip: true,
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function getUserCompanyInfos(apiconf, staff_ids) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "company/info/limited",
            query: { staff_id: staff_ids },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function getUserBrandInfos(apiconf, staff_ids) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "brand/list/limited",
            query: { staff_id: staff_ids },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 一覧取得
export function getList(apiconf, staff_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/list",
            query: { staff_id: staff_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// アバター取得
export function getAvatar(apiconf, account_id) {
    return new Promise(function (resolve, reject) {
        api_common.callGetApi({
            conf: apiconf,
            path: "preview/avatar/" + account_id,
            headers: { 'responseType': 'arraybuffer' }
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function getAvatarUrl(apiconf, account_id) {
    return api_common.buildApiUrl(apiconf, "preview/avatar/" + account_id)
}

// プロファイル更新
export function updateProfile(apiconf, account_id, profile, exist_avatar, attached_files) {
    let formdata = [
        {
            is_json: true,
            set_token: true,
            column: "posted",
            value: {
                account_id: account_id,
                profile: profile,
                avatar: exist_avatar,
            }
        }
    ]
    if (attached_files) {
        attached_files.forEach(attached => {
            formdata.push({ column: 'attached', value: attached });
        })
    }
    return api_common.callPostFormApi({
        conf: apiconf,
        path: "account/update",
        formdata: formdata,
    })
}

// 新規企業登録
export function initCompany(apiconf, profile, company, brand, shop) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/init/admin",
            query: { profile: profile, company: company, brand: brand, shop: shop },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 別企業所属済ユーザの新規企業登録
export function addCompany(apiconf, profile, company, brand, shop) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/init/admin",
            query: { profile: profile, company: company, brand: brand, shop: shop },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 企業登録通知
export function noticeInitCompany(apiconf, company_name) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "company/regist",
            query: { company_name: company_name },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 新規招待登録
export function initInvitation(apiconf, profile, invitation_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/init",
            query: { profile: profile, invitation_id: invitation_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 新規企業参加
export function joinAssociate(apiconf, account_id, profile, invitation_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/init/associate",
            query: { account_id: account_id, profile: profile, invitation_id: invitation_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 新規店舗登録
export function initShopStaff(apiconf, account_id, associate_id, profile, invitation_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/init/staff",
            query: { account_id: account_id, associate_id: associate_id, profile: profile, invitation_id: invitation_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// アカウント削除
export function deleteAccount(apiconf, staff_id, id_token) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "cancel/membership",
            query: { staff_id: staff_id, id_token: id_token },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 利用規約
export function getTerms(apiconf, id_token, term_type) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/terms/status",
            query: { term_type: term_type },
            id_token: id_token,
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function agreeTerms(apiconf, id_token, term_type, agree) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/terms/agree",
            query: { term_type: term_type, agree: agree },
            id_token: id_token,
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// Cognitoユーザ名取得
export function getCognitoUser(apiconf) {
    return api_common.callPostApi({
        conf: apiconf,
        path: "cognito/getuser",
        query: {},
    })
}

// Cognitoカスタムクレーム更新
export function updateCognitoCustomclaims(apiconf, staff_id, access_token, attributes) {
    return api_common.callPostApi({
        conf: apiconf,
        path: "cognito/update/claims",
        query: { staff_id: staff_id, access_token: access_token, attributes: attributes },
    })
}

export default {
    getUserProfile,
    getUserStaffGroup,
    getUserStaffRoles,
    getUserCompanyInfos,
    getUserBrandInfos,
    getList,
    getAvatar,
    getAvatarUrl,
    updateProfile,
    initCompany,
    addCompany,
    noticeInitCompany,
    initInvitation,
    joinAssociate,
    initShopStaff,
    deleteAccount,
    getTerms,
    agreeTerms,
    getCognitoUser,
    updateCognitoCustomclaims,
}