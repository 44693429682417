<template>
  <div id="modal">
    <div class="sp-modal-overlay" v-if="isDisplayModeSP()">
      <div class="sp-modal-confirm">
        <div class="sp-modal-confirm-title">{{ modal_title }}</div>
        <div
          v-html="modal_message"
          class="sp-modal-confirm-text sp-word-space-wrap"
        ></div>
        <div class="sp-modal-btns">
          <div class="sp-modal-btn">
            <button class="sp-modal-delete" @click="close(true)">
              {{ modal_btntext }}
            </button>
          </div>
          <div class="sp-modal-btn" v-if="view_cancel">
            <button class="sp-modal-cancel" @click="close(false)">
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-overlay" v-else>
      <div class="modal-content permanently-delete">
        <div class="modal-body">
          <div class="modal-body-inner">
            <p class="permanently-delete-strong">
              {{ modal_title }}
            </p>
            <p class="word-space-wrap" v-html="modal_message"></p>
          </div>
        </div>
        <div class="modal-bottom">
          <div class="modal-bottom-inner">
            <div class="cancel" v-if="view_cancel">
              <button @click="close(false)">キャンセル</button>
            </div>
            <div :class="modal_closeclass">
              <button @click="close(true)">{{ modal_btntext }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    btntext: String,
    closeClass: String,
    close: Function,
    view_cancel: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isDisplayModeSP } = require("@/helpers/common/common_util");
    return { isDisplayModeSP };
  },
  data: () => ({
    modal_title: "",
    modal_message: "",
    modal_btntext: "",
    modal_closeclass: "",
  }),
  created() {
    this.modal_title = this.title ?? "削除します";
    this.modal_message = this.message ?? "削除します。よろしいですか？";
    this.modal_btntext = this.btntext ?? "削除";
    this.modal_closeclass = this.closeClass ?? "del";
  },
};
</script>
