<template>
  <header class="header">
    <div class="toolbar">
      <div class="toolbar-inner">
        <div class="toolbar-logo">
          <div class="toolbar-logo-img">
            <img :src="getLogoFPortal()" alt="" style="width: 120px" />
          </div>
        </div>

        <div class="toolbar-nav">
          <ul>
            <template v-for="(link, i) in parents.header_links" :key="i">
              <li v-if="!link.sublinks" :class="link.color_class">
                <a class="toolbar-first-link" @click="pageMove(link)">{{
                  link.name
                }}</a>
              </li>
              <li v-else class="toolbar-various-settings" :class="link.color_class">
                <a class="toolbar-first-link">{{ link.name }}</a>
                <ul class="toolbar-nav-child">
                  <template v-for="(sublink, j) in link.sublinks" :key="j">
                    <li>
                      <a @click="pageMove(sublink)">{{ sublink.name }}</a>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </ul>
        </div>

        <div class="toolbar-menu toolbar-nav">
          <div class="toolbar-icons">
            <div v-for="(menu, i) in getPortalHeaderIconLinks()" :key="i">
              <template v-if="!menu.sublinks">
                <button :class="{ active: menu.active }" @click="pageMove(menu)">
                  <i aria-hidden="true" :class="'mdi ' + menu.icon"></i>
                  <!-- 通知バッヂ -->
                  <div v-if="menu.is_newer" class="notification-badge"></div>
                  <div v-if="menu.name == icon_name && unread_count && unread_count.unread" class="notification-badge">
                  </div>
                </button>
              </template>
              <template v-else>
                <div class="toolbar-various-settings toolbar-icons toolbar-icon-has-sublinks p-relative">
                  <button :class="{ active: menu.active }">
                    <i aria-hidden="true" :class="'mdi ' + menu.icon"></i>
                  </button>
                  <ul class="toolbar-nav-child">
                    <template v-for="(sublink, j) in menu.sublinks" :key="j">
                      <li>
                        <a @click="pageMove(sublink)">{{ sublink.name }}</a>
                      </li>
                    </template>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="toolbar-account">
          <div class="toolbar-account-btn">
            <span class="toolbar-store-name">{{
              viewCompanyOrShopName()
            }}</span>
            <v-avatar v-if="url_avatar" size="40">
              <img v-if="url_avatar" :src="getAccountAvatarUrl()" @error="url_avatar = false" />
            </v-avatar>

            <div v-else class="v-avatar">
              <i class="mdi mdi-account"></i>
            </div>
          </div>
          <div class="toolbar-account-detail">
            <div class="toolbar-account-name" @click="pageMove('/account')">
              <div class="toolbar-account-icon">
                <div class="v-avatar" v-if="url_avatar">
                  <v-avatar size="40">
                    <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" @error="url_avatar = false" cover />
                  </v-avatar>
                </div>
                <div class="v-avatar" v-else>
                  <i class="mdi mdi-account"></i>
                </div>
              </div>
              <div class="font-weight-bold mt-2">
                {{ getUserName() }}
              </div>
            </div>
            <div class="toolbar-account-place" @click="pageMove('/account')">
              <div class="toolbar-account-company">
                {{ getUserCompanyFullName() }}<br />
                {{ getUserBrandName() }}
              </div>
              <div class="toolbar-account-store font-weight-bold">
                {{ getUserShopName() }}
              </div>
              <div class="toolbar-account-role">
                {{ getUserRoleName() }}
              </div>
            </div>
            <div class="toolbar-account-change" v-if="!isUserShopAccount()">
              <button @click="viewChangeShop()">
                <i class="mdi mdi-cached"></i>
                店舗切り替え
              </button>
            </div>
            <div class="toolbar-account-reload">
              <button @click="pageMove('/auth/getuserinfo')">
                最新の情報を取得
              </button>
            </div>
            <div class="toolbar-account-logout">
              <button @click="pageMove('/auth/signout')">サインアウト</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChangeShopModal v-if="change_shop_modal" :close="changeShop" />
    <ConfirmModal v-if="confirm_modal" title="編集を中断します。" message="編集を中断します。よろしいですか？" :close="execMove" />
  </header>
</template>

<script>
import ChangeShopModal from "@/components/common/change_shop/PcChangeShopModal";
import ConfirmModal from "@/components/common/ConfirmModal";
export default {
  props: ["parents"],
  components: { ChangeShopModal, ConfirmModal },
  setup() {
    const { getUserName, getUserCompanyFullName, getUserBrandName, getUserRoleName,
      isUserHeadShop, getUserShopName, getAccountAvatarUrl, isFranchiseOwnerHead,
      getUserFcOwnerBrandName, getUserCompanyName, isShopShareMode, isUserShopAccount } = require('@/helpers/auth/auth_util')
    const { getPortalHeaderIconLinks } = require('@/helpers/portal/portal_util')
    const { pageMove, getLogoFPortal, openExternalWindow, isSystemApp, getChatUnreadCount } = require('@/helpers/common/common_util')
    const { apiParallelCall } = require('@/helpers/api_common')
    const { readMembers, deletePolling } = require('@/components/common/chat/chat')
    return {
      getUserName, getUserCompanyFullName, getUserBrandName, getUserRoleName,
      isUserHeadShop, getUserShopName, getAccountAvatarUrl, isFranchiseOwnerHead,
      getUserFcOwnerBrandName, getUserCompanyName, isShopShareMode, isUserShopAccount,
      getPortalHeaderIconLinks,
      pageMove, getLogoFPortal, openExternalWindow, isSystemApp, getChatUnreadCount,
      apiParallelCall,
      readMembers, deletePolling,
    }
  },
  data: () => ({
    url_avatar: true,

    change_service: false,
    change_shop_modal: false,
    confirm_modal: false,
    unread_count: 0,

    icon_name: 'チャット',
    params: {
      success: "",
      error: "",
      loading: true,

      rooms: {
        shops: [],
        groups: [],
        directs: [],
      },
    },
  }),
  async created() {
    this.unread_count = await this.getChatUnreadCount()
  },

  methods: {
    viewCompanyOrShopName() {
      if (this.isUserHeadShop()) {
        return this.isFranchiseOwnerHead() ? this.getUserFcOwnerBrandName() : this.getUserCompanyName()
      } else {
        return this.getUserShopName();
      }
    },

    checkHeaderLink(link) {
      this.debugLog(link);
      if (link.external) {
        this.openExternalWindow(link.external);
      } else {
        this.pageMove(link.to);
      }
    },

    viewChangeShop() {
      this.change_shop_modal = true;
    },
    changeShop() {
      this.change_shop_modal = false;
    },

    // 遷移確認
    confirmMove(event) {
      if (this.parents.is_move_confirm) {
        this.save_event = event;
        this.confirm_modal = true;
        return true;
      }
      return false;
    },
    execMove(is) {
      // eslint-disable-line
      if (is) {
        // eslint-disable-line
      }
      this.confirm_modal = false;
    },
  },
};
</script>

<style scoped>
.v-application {
  background-color: unset !important;
}

.v-application ul {
  padding-left: 0 !important;
}

.v-application a {
  color: inherit;
}

.toolbar-icons i {
  color: #666666;
}

.toolbar-account {
  margin-left: 24px;
  position: relative;
}

.toolbar-account-name {
  padding: 16px;
  border-radius: 0.75rem;
}

.toolbar-account p {
  margin: 0;
}

.toolbar-account-btn {
  background: #f8f5f5;
  padding: 4px 12px;
  border-radius: 24px;
}

.toolbar-account-btn.shareActive {
  background: #103087;
  color: #fff;
}

.toolbar-account-btn img {
  border-radius: 50%;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.toolbar-account-btn>* {
  display: inline;
  vertical-align: middle;
}

.toolbar-account-icon img {
  width: 100%;
}

.toolbar-store-name {
  margin-right: 8px;
}

.toolbar-account-detail {
  position: absolute;
  top: 100%;
  background-color: #fff;
  padding: 16px 16px 0;
  right: 0;
  width: 310px;
  box-shadow: 0px 0px 24px #00000029;
  border-radius: 0.75rem;
  z-index: 10;
  display: none;
}

.toolbar-account-btn:hover {
  cursor: pointer;
}

.toolbar-account-btn:hover+.toolbar-account-detail,
.toolbar-account-detail:hover {
  display: block;
}

.toolbar-account-place {
  border-radius: 0.75rem;
  padding: 16px;
}

.toolbar-account-place:hover,
.toolbar-account-name:hover {
  background: #f8f5f5;
}

.toolbar-account .toolbar-account-store {
  margin-top: 8px;
  font-weight: bold;
}

.toolbar-account-change {
  margin-top: 8px;
}

.toolbar-account-change i {
  font-size: 1.25rem;
  margin-right: 8px;
}

.toolbar-account-change button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4px 0;
  border: 1px solid #1B6DBE;
  border-radius: 4px;
  color: #1562A4;
  background-color: #fff;
}

.toolbar-account-change button span {
  vertical-align: middle;
}

.toolbar-account-reload,
.toolbar-account-logout {
  padding: 16px 0;
  border-top: 1px solid #ddd;
  text-align: center;
  color: #1B6DBE;
}

.toolbar-account-reload {
  margin-top: 16px;
}

.toolbar-account-logout a {
  color: #666666;
}

.toolbar-logo {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.toolbar-logo-img img {
  vertical-align: middle;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: #ffb324;
  border-radius: 50%;
}
</style>