import config from "@/configs/auth";
import storeAuth from "@/helpers/store/storeAuthHelper"
import { isSigninTypeExternal, isProxySigninAccount } from "@/helpers/auth/auth_util"

import api_auth from "./api/auth.js";
import api_account from "./api/account.js";
import api_spec from "./api/spec.js";

export function getExpiredSec() {
    return config.expired_sec;
}

// 認証
export function apiAuthSignin(userid, passwd) {
    return api_auth.signin(config.api, userid, passwd);
}
export function apiAuthSignout(staff_id, access_token = "") {
    return api_auth.signout(config.api, access_token, staff_id);
}
export function apiTokenRefresh() {
    return api_auth.tokenRefresh(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetRefreshToken(), isSigninTypeExternal() ? 1 : 0);
}
export function apiAuthExternalSignin(account_verify, id_token, access_token) {
    return api_auth.signinExternal(config.api, account_verify, id_token, access_token);
}

// アカウント
export function apiGetUserProfile() {
    return api_account.getUserProfile(config.api);
}
export function apiGetUserStaffGroup(staff_id, conditions) {
    return api_account.getUserStaffGroup(config.api, staff_id, conditions);
}
export function apiGetUserStaffRoles(staff_id) {
    return api_account.getUserStaffRoles(config.api, staff_id);
}
export function apiGetUserCompanyInfos(staff_ids) {
    return api_account.getUserCompanyInfos(config.api, staff_ids);
}
export function apiGetUserBrandInfos(staff_ids) {
    return api_account.getUserBrandInfos(config.api, staff_ids);
}
export function apiAccountGetAvatar(account_id) {
    return api_account.getAvatar(config.api, account_id);
}
export function apiAccountGetAvatarUrl(account_id) {
    return api_account.getAvatarUrl(config.api, account_id);
}
export function apiAccountInitCompany(profile, company, brand, shop) {
    return api_account.initCompany(config.api, profile, company, brand, shop);
}
export function apiAccountAddCompany(profile, company, brand, shop) {
    return api_account.addCompany(config.api, profile, company, brand, shop);
}
export function apiAccountNoticeInitCompany(company_name) {
    return api_account.noticeInitCompany(config.api, company_name);
}
export function apiAccountInitInvitation(profile, invitation_id) {
    return api_account.initInvitation(config.api, profile, invitation_id);
}
export function apiAccountJoinAssociate(profile, invitation_id) {
    return api_account.joinAssociate(config.api, storeAuth.storeGetAccountId(), profile, invitation_id);
}
export function apiAccountInitShop(associate_id, profile, invitation_id) {
    return api_account.initShopStaff(config.api, storeAuth.storeGetAccountId(), associate_id, profile, invitation_id);
}
export function apiAccountUpdate(profile, exist_avatar, attached_files) {
    return api_account.updateProfile(config.api, storeAuth.storeGetAccountId(), profile, exist_avatar, attached_files);
}
export function apiAccountDelete() {
    if (!isProxySigninAccount()) {
        return api_account.deleteAccount(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetIdToken());
    }
}
export function apiAccountGetTerms(id_token, term_type) {
    return api_account.getTerms(config.api, id_token, term_type);
}
export function apiAccountAgreeTerms(id_token, term_type, agree) {
    return api_account.agreeTerms(config.api, id_token, term_type, agree);
}
export function apiGetCognitoUser() {
    return api_account.getCognitoUser(config.api);
}
export function apiUpdateCognitoCustomclaims(attributes) {
    return api_account.updateCognitoCustomclaims(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetAccessToken(), attributes);
}

// 設定
export function apiSpecAccountList() {
    return api_spec.listAccount(config.api, storeAuth.storeGetAccountId());
}
export function apiSpecAccountUpdate(spec) {
    return api_spec.updateAccount(config.api, storeAuth.storeGetAccountId(), spec);
}
export function apiSpecCompanyGetTaskScheduleUiVer() {
    return api_spec.getCompanyTaskScheduleUiVer(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiSpecCompanySetTaskScheduleUiVer(spec_value) {
    return api_spec.setCompanyTaskScheduleUiVer(config.api, storeAuth.storeGetSelectedStaffId(), spec_value);
}
export function apiSpecShopList(shop_id) {
    return api_spec.listShop(config.api, storeAuth.storeGetSelectedStaffId(), shop_id);
}
export function apiSpecShopUpdate(spec) {
    return api_spec.updateShop(config.api, storeAuth.storeGetSelectedStaffId(), spec);
}
export function apiSpecStaffList() {
    return api_spec.listStaff(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiSpecStaffUpdate(spec) {
    return api_spec.updateStaff(config.api, storeAuth.storeGetSelectedStaffId(), spec);
}

export default {
    getExpiredSec,

    // 認証
    apiAuthSignin,
    apiAuthSignout,
    apiTokenRefresh,
    apiAuthExternalSignin,

    // アカウント
    apiGetUserProfile,
    apiGetUserStaffGroup,
    apiGetUserStaffRoles,
    apiGetUserCompanyInfos,
    apiGetUserBrandInfos,
    apiAccountGetAvatar,
    apiAccountGetAvatarUrl,
    apiAccountInitCompany,
    apiAccountAddCompany,
    apiAccountNoticeInitCompany,
    apiAccountInitInvitation,
    apiAccountJoinAssociate,
    apiAccountInitShop,
    apiAccountUpdate,
    apiAccountDelete,
    apiAccountGetTerms,
    apiAccountAgreeTerms,
    apiGetCognitoUser,
    apiUpdateCognitoCustomclaims,

    // 設定
    apiSpecAccountList,
    apiSpecAccountUpdate,
    apiSpecCompanyGetTaskScheduleUiVer,
    apiSpecCompanySetTaskScheduleUiVer,
    apiSpecShopList,
    apiSpecShopUpdate,
    apiSpecStaffList,
    apiSpecStaffUpdate,
}