import api_common from "@/helpers/api_common.js";

export default {

    // 企業リンク
    getCompanyLinks(apiconf, staff_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "company/links",
            query: { staff_id: staff_id },
        })
    },
    createCompanyLink(apiconf, staff_id, links) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "company/links/create",
            query: { staff_id: staff_id, links: links },
        })
    },
    updateCompanyLink(apiconf, staff_id, links) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "company/links/update",
            query: { staff_id: staff_id, links: links },
        })
    },

    // 企業リンクアイコン
    addAttachment(apiconf, staff_id, specific_key, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    specific_key: specific_key,
                }
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "specificlink/event/add/attachment",
            formdata: formdata,
        })
    },
    removeAttachment(apiconf, staff_id, specific_key) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "specificlink/event/rm/attachment",
            query: { staff_id: staff_id, specific_key: specific_key },
        })
    },
    // ファイルプレビュー
    previewIcon(apiconf, staff_id, specific_key, file_id) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/specificlink/file",
            query: {
                staff_id: staff_id,
                specific_key: specific_key,
                file_id: file_id,
            },
        })
    },

    // アソシエイトリンク
    getAssociateLinks(apiconf, staff_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/links",
            query: { staff_id: staff_id },
        })
    },
    createAssociateLink(apiconf, staff_id, links) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/links/create",
            query: { staff_id: staff_id, links: links },
        })
    },
    updateAssociateLink(apiconf, staff_id, links) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/links/update",
            query: { staff_id: staff_id, links: links },
        })
    },
}