export default {
    cognitoBaseURL: "https://auth.eatery-portal.com",
    region: "ap-northeast-1",
    pool_id: "ap-northeast-1_EUN24FCgV",
    provider: {
        external: {
            client_id: "7sn207gf3as2r794af25s4qt5l",
            client_secret: "ev9ca350mof30rdcj67d5mv30hf8k61j5dml9tj4qj37lsk6jq",
        },
        app: {
            client_id: "2rb5j4kt3tq9fouvuvaegopc17",
            client_secret: "NULL",
        },
    }
}