<template>
  <div>
    <footer class="sp-footer-menus">
      <div v-for="(link, i) in getPortalSpFooterLinks()" :key="i">
        <button @click="clickLink(link)" :class="{ active: link.active }" class="sp-p-relative">
          <div v-if="link.name == is_chat && unread_count > 0" class="notification-badge"></div>
          <div>
            <v-icon v-if="link.icon">{{ link.icon }}</v-icon>
            <img v-if="link.image" :src="link.image" style="width: 18px" />
          </div>
          <div class="mt-2">
            {{ link.name }}
          </div>
        </button>
      </div>
    </footer>
    <ExecutingOverlay ref="execute" />
  </div>
</template>

<script>
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
export default {
  components: { ExecutingOverlay },
  setup() {
    const { isShopShareMode } = require('@/helpers/auth/auth_util')
    const { getPortalSpFooterLinks, portalMenuMove } = require('@/helpers/portal/portal_util') 
    const { getChatUnreadCount } = require('@/helpers/common/common_util')
    return {
      isShopShareMode,
      getPortalSpFooterLinks, portalMenuMove,
      getChatUnreadCount,
    }
  },
  data: () => ({
    is_chat: 'チャット',
    unread_count: 0,

    params: {
      success: "",
      error: "",
      loading: true,

      rooms: {
        shops: [],
        groups: [],
        directs: [],
      },
    },
  }),
  async created() {
    this.unread_count = await this.getChatUnreadCount()
  },
  methods: {
    clickLink(link) {
      if (!link.active) {
        this.portalMenuMove(this, link);
      }
    },
  },
};
</script>

<style>
.notification-badge {
  position: absolute;
  top: 0;
  right: 0px;
  width: 9px;
  height: 9px;
  background-color: #ffb324;
  border-radius: 50%;
}
</style>