import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/list",
            query: { staff_id: staff_id, page: page }
        })
    },

    // 更新
    update(apiconf, staff_id, associate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/update",
            query: { staff_id: staff_id, associate: associate },
        })
    },

    // 詳細取得
    getInfo(apiconf, staff_id, associate_id, include_closed_shop) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/detail",
            query: { staff_id: staff_id, associate_id: associate_id, include_closed_shop: include_closed_shop },
        })
    },

    getEmployeeProfileInfo(apiconf, staff_id, associate_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/info",
            query: { staff_id: staff_id, associate_id: associate_id },
        })
    },

    // 店舗参加
    joinShop(apiconf, staff_id, associate_id, shops) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/join/shops",
            query: { staff_id: staff_id, associate: { associate_id: associate_id, shops: shops } },
        })
    },

    // 店舗脱退
    exitShops(apiconf, staff_id, associate_id, shops) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/exit/shops",
            query: { staff_id: staff_id, associate: { associate_id: associate_id, shops: shops } },
        })
    },

    // 所属エリア
    getJoinAreas(apiconf, staff_id, associate_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "associate/areas",
            query: { staff_id: staff_id, conditions: { associate_id: associate_id } },
        })
    },
}