import config from "@/configs/service"
import { copyJson } from "@/helpers/common/common_util"
import { getEnabledRoutes } from "@/helpers/common/route";
import { isUserHeadShop } from "@/helpers/auth/auth_util";
import { isEnabledRoleCd, isEnableRoutePolicy } from "@/helpers/common/role";

// メインタイトル取得
export function getServiceBaseTitle() {
  return config.maintitle
}
// サービス基準パス
export function getServiceBasePath() {
  return config.basepath
}
// ベースカラー
export function getServiceBaseColor() {
  return config.basecolor.base
}
export function getServiceBaseTextColor() {
  return config.basecolor.text
}
export function getServiceBaseBackColor() {
  return config.basecolor.background
}
export function isServiceBaseColorDark() {
  return config.basecolor.isdark
}
export function getServiceBaseContainerBackColor() {
  return config.basecolor.container_background
}
export function getServiceBaseInputColor() {
  return config.basecolor.input
}
export function getServiceBaseShopShareModeColor() {
  return config.basecolor.shop_share_mode
}
export function getHeaderLinks() {
  return config.menus
}

// MEMO:サービス毎にロジックを書き換える
export function getEnabledHeaderLinks(selected) {
  let links = []
  Object.keys(getHeaderLinks()).forEach(key => {
    let link = copyJson(getHeaderLinks()[key]);
    link.color_class = "";
    if (link.is_head != undefined) {
      if (link.is_head && !isUserHeadShop()) {
        return
      } else if (!link.is_head && isUserHeadShop()) {
        return
      }
    }
    if (link.role_cds) {
      if (!isEnabledRoleCd(link.role_cds)) {
        return
      }
    }
    if (link.policy) {
      if (!isEnableRoutePolicy(link.policy.function_cd, link.policy.crud)) {
        return
      }
    }
    if (link.sublinks) {
      let sublinks = []
      link.sublinks.forEach(sublink => {
        if (sublink.role_cds) {
          if (!isEnabledRoleCd(sublink.role_cds)) {
            return
          }
        }
        if (sublink.policy) {
          if (!isEnableRoutePolicy(sublink.policy)) {
            return
          }
        }
        sublinks.push(sublink)
      })
      if (sublinks.length <= 0) {
        return
      }
      link.sublinks = sublinks
      link.color_class += "toolbar-various-settings ";
    }
    if (key == "master") {
      link.sublinks = getEnabledRoutes("master");
      if(link.sublinks.length == 0) return
      link.color_class += "toolbar-various-settings ";
    }
    if (key == selected) {
      link.color_class += "active";
    }
    link.key = key;
    links.push(link)
  })
  return links
}

export default {
  getServiceBaseTitle,
  getServiceBasePath,
  getServiceBaseColor,
  getServiceBaseTextColor,
  getServiceBaseBackColor,
  isServiceBaseColorDark,
  getServiceBaseContainerBackColor,
  getServiceBaseInputColor,
  getServiceBaseShopShareModeColor,
  getEnabledHeaderLinks,
  getHeaderLinks,
}
