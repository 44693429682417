export default
    [
        {
            path: '/admin',
            component: () => import('@/components/portal/Index'),
            children: [
                {
                    path: 'services',
                    name: 'admin-services',
                    component: () => import('@/components/portal/admin/services/IndexAdminServices'),
                    meta: { title: 'ホーム表示設定', policy: { is_sp: false, } },
                },
            ]
        },
    ]

