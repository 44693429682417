import config from "@/configs/portal";

import api_invitation from "./api/invitation.js";
import api_request_vmanage from "./api/request_vmanage";
import api_service_links from "./api/service_links";

import { isiOS } from "@/helpers/common/common_util"
import { buildApiUrl } from "@/helpers/api_common"
import storeAuth from "@/helpers/store/storeAuthHelper"

// 招待
export function apiInvitationApplycheck(invitation_id) {
  return api_invitation.applycheck(config.api, invitation_id);
}
export function apiInvitationCreateCode(invitation) {
  return api_invitation.create(config.api, storeAuth.storeGetSelectedStaffId(), invitation);
}
export function apiInvitationGetList(page = 1) {
  return api_invitation.getList(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetSelectedCompanyId(), page);
}
export function apiInvitationSendEmail(send_to, invitation_id, url) {
  return api_invitation.sendEmail(config.api, storeAuth.storeGetSelectedStaffId(), send_to, invitation_id, url);
}
export function apiInvitationDownloadTemplate() {
  return api_invitation.downloadTemplate(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiInvitationUploadExcelFile(attached_files) {
  return api_invitation.uploadExcelFile(config.api, storeAuth.storeGetSelectedStaffId(), attached_files);
}

// V-Manage利用申し込み
export function apiRequestVManageGetInfo() {
  return api_request_vmanage.getRequestInfo(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetSelectedCompanyId());
}
export function apiRequestVManageExec(request) {
  return api_request_vmanage.execRequest(config.api, storeAuth.storeGetSelectedStaffId(), request);
}

// V-Manage追加SU利用申し込み
export function apiAddSetupRequestVManageGetInfo() {
  return api_request_vmanage.getAddSetupRequestInfo(config.api, storeAuth.storeGetSelectedStaffId(), storeAuth.storeGetSelectedCompanyId());
}
export function apiAddSetupRequestVManageExec(request) {
  return api_request_vmanage.execAddSetupRequest(config.api, storeAuth.storeGetSelectedStaffId(), request);
}

// サービスリンク
export function apiServiceLinksCompany() {
  return api_service_links.getCompanyLinks(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiServiceLinksCreateCompany(links) {
  return api_service_links.createCompanyLink(config.api, storeAuth.storeGetSelectedStaffId(), links);
}
export function apiServiceLinksUpdateCompany(links) {
  return api_service_links.updateCompanyLink(config.api, storeAuth.storeGetSelectedStaffId(), links);
}
export function apiServiceLinksAddIcon(specific_key, attached_files) {
  return api_service_links.addAttachment(config.api, storeAuth.storeGetSelectedStaffId(), specific_key, attached_files);
}
export function apiServiceLinksRemoveIcon(specific_key) {
  return api_service_links.removeAttachment(config.api, storeAuth.storeGetSelectedStaffId(), specific_key);
}
export function apiServiceLinksAssociate() {
  return api_service_links.getAssociateLinks(config.api, storeAuth.storeGetSelectedStaffId());
}
export function apiServiceLinksCreateAssociate(links) {
  return api_service_links.createAssociateLink(config.api, storeAuth.storeGetSelectedStaffId(), links);
}
export function apiServiceLinksUpdateAssociate(links) {
  return api_service_links.updateAssociateLink(config.api, storeAuth.storeGetSelectedStaffId(), links);
}
export function apiServiceLinksPreviewIcon(specific_key, file_id) {
  return api_service_links.previewIcon(config.api, storeAuth.storeGetSelectedStaffId(), specific_key, file_id);
}

// ストリーミング動画取得URL
export function getPortalStreamVideoApi(file_id, service_id, service_type) {
  if (isiOS()) {
    return getPortalHlsStreamUrl(file_id, service_type)
  } else {
    return getPortalStreamVideoUrl(file_id, service_id, service_type)
  }
}
export function getPortalStreamVideoUrl(file_id, service_id, service_type) {
  let url = buildApiUrl(config.api, "stream/video")
  url += "?vid=" + file_id;
  url += "&sid=" + service_id;    // file_id | chat_id | forum_id
  url += "&scd=" + service_type;  // task | tasksub | emergency
  url += "&uid=" + storeAuth.storeGetSelectedStaffId(true);
  url += "&token=" + storeAuth.storeGetIdToken();
  return url
}
export function getPortalHlsStreamUrl(file_id, service_type) {
  let url = buildApiUrl(config.api, "hls/video")
  url += "/" + storeAuth.storeGetKeyAsSelectedCompany();
  url += "/" + service_type;  // file | chat | forum
  url += "/" + file_id + "_hls.m3u8";
  url += "?token=" + storeAuth.storeGetIdToken();
  return url
}

export default {
  // 招待
  apiInvitationApplycheck,
  apiInvitationCreateCode,
  apiInvitationGetList,
  apiInvitationSendEmail,
  apiInvitationDownloadTemplate,
  apiInvitationUploadExcelFile,

  // V-Manage利用申し込み
  apiRequestVManageGetInfo,
  apiRequestVManageExec,

  // サービスリンク
  apiServiceLinksCompany,
  apiServiceLinksCreateCompany,
  apiServiceLinksUpdateCompany,
  apiServiceLinksAssociate,
  apiServiceLinksCreateAssociate,
  apiServiceLinksUpdateAssociate,

  // ストリーミング動画取得URL
  getPortalStreamVideoApi,
  getPortalStreamVideoUrl,
  getPortalHlsStreamUrl,
}