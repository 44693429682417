import config from "@/configs/portal"
import ssoconfig from "@/configs/sso"
import serviceconfig from "@/configs/service"
import storeAuth from "@/helpers/store/storeAuthHelper"
import storeActivate from "@/helpers/store/storeActivateHelper"
import storeSystem from "@/helpers/store/storeSystemHelper"
import {
  getRoutePath, routerPush, isDisplayModeSP, getHostURL, isVManageUsingShop,
  getCurrentRoutePath, getSystemType, isSystemApp, startExecute, openExternalWindow,
  cryptoAES, decryptoAES, browserSiteUrl
} from "@/helpers/common/common_util"
import { isNewerNotifications } from "@/helpers/common/notifications"
import { isEnableRoutePolicy } from "@/helpers/common/role";
import { getEnabledRoutes } from "@/helpers/common/route";
import { isEnabledTimemng, isUserHeadShop, readStaffGroupSso } from "@/helpers/auth/auth_util";
import { isEnvProd } from "@/helpers/environment";
import { getHeaderLinks } from "@/helpers/service_util"
import { debugLog } from '@/helpers/common/datadog'

// 店舗切り替え後ページ
export function getChangeShopBasePage() {
  return "/"
}

// 承認要求
export function approvalRequestApproved() {
  return config.approval_request.approved;
}
export function approvalRequestDecline() {
  return config.approval_request.decline;
}

// 招待通知方法取得
export function getInviteSendTypeName(invite) {
  if (invite.send_type == "email") {
    return "メール";
  } else if (invite.send_type == "line") {
    return "Line";
  } else {
    return "通知なし";
  }
}

// ヘッダーアイコンリンク
export function getPortalHeaderIconLinks() {
  let enables = []
  for (const route of getEnabledRoutes('header_toolbar')) {
    if (route.path.includes("notification")) {
      route.is_newer = isNewerNotifications()
    }
    enables.push(route)
  }
  // ヘルプセンター
  enables.push({
    icon: "mdi-help-circle",
    linked_path: "/help/contact",
    sublinks: [
      {
        name: "F-Portalヘルプセンターはこちら",
        linked_path: serviceconfig.help_page_url,
        external_browser_only: true,
        // target: "_help"
      },
      {
        name: "お問い合わせフォームはこちら",
        linked_path: "/help/contact",
      }
    ]
  })
  // 選択中表示
  enables.map(v => v.active = v.linked_path == getCurrentRoutePath() ? true : false)
  return enables
}

// フッターリンク
export function getPortalSpFooterLinks() {
  let links = []
  links.push({
    icon: "mdi-home",
    linked_path: "/",
    name: "ホーム",
  })
  const chat = getEnabledRoutes("footer_toolbar_chat")
  if (chat && chat.length > 0) {
    links.push(...chat)
  }
  const notify = getEnabledRoutes("footer_toolbar_notification")
  if (notify && notify.length > 0) {
    notify.name = "通知"
    notify.map(v => v.is_newer = isNewerNotifications())
    links.push(...notify)
  }
  const timemng = storeAuth.storeGetSelectedLinks().find(v => v.link_cd == "timemng")
  if (timemng) {
    if (!(isEnabledTimemng() && !isUserHeadShop() && !isVManageUsingShop())) {
      // IPアドレス利用制限チェック
      if(!(storeAuth.storeGetShopIpAddressLimited() && !isUserHeadShop())){
        timemng.name = "V-Manage"
        timemng.image = require("@/assets/services/V-Manage.png")
        links.push(timemng)
      }
    }
  }
  links.push({
    icon: "mdi-dots-horizontal",
    linked_path: "/menus",
    name: "メニュー",
  })
  links.map(v =>
    v.active = v.linked_path == getRoutePath() || v.linked_path == getRoutePath() + "/")
  return links
}

// ポータルサービス画像パス
export function getPortalServiceImagePath(img) {
  try {
    return require('@/assets/services/' + img.trim())
  } catch (err) {
    debugLog(err)
  }
}

// ポータルメニュー取得
export function getPortalMenus() {
  let menus = []
  getHeaderLinks().menus.sublinks.forEach(menu => {
    if (menu.policy) {
      if (!isEnableRoutePolicy(menu.policy)) {
        return
      }
    }
    menus.push(menu)
  })
  return menus
}

// 管理者メニュー取得
export function getPortalAdminMenus() {
  let menus = []
  getHeaderLinks().admin_menus.sublinks.forEach(menu => {
    if (menu.policy) {
      if (!isEnableRoutePolicy(menu.policy)) {
        return
      }
    }
    menus.push(menu)
  })
  return menus
}

// ポータルメニュー遷移
export function portalMenuMove(self, link) {
  if (link.link_cd == "timemng") {
    // タイムマネジメント
    if (isEnabledTimemng()) {
      if (!(!isUserHeadShop() && !isVManageUsingShop())) {
        redirectTimemanagement(self)
      }
    } else {
      routerPush("/request/vmanage");
    }
  } else if (link.link_cd == "qrscan") {
    // QRスキャン
    execInviteQrScan()
  } else if (link.linked_path) {
    // 内部遷移
    routerPush(link.linked_path);
  } else if (link.link_data.to) {
    // 内部遷移
    routerPush(link.link_data.to);
  } else if (link.link_data.external) {
    // 外部遷移
    _linkExternal(link);
  } else if (link.link_data.btob) {
    // BtoB SSO遷移
    _linkBtobSSO(link);
  }

  // 外部遷移
  function _linkExternal(link) {
    let url = link.link_data.external.url
    if (isDisplayModeSP() && link.link_data.external.spurl) {
      url = link.link_data.external.spurl
    }
    openExternalWindow(url, "_" + link.link_cd);
  }

  // BtoB SSO
  async function _linkBtobSSO(link) {
    const target = "_btob"
    const linkinfo = link.link_data ? link.link_data.btob : null

    // SSO情報取得
    let staff_sso = await readStaffGroupSso()
    if (!staff_sso) {
      staff_sso = storeAuth.storeGetSelectedStaffSso()
    }
    const sso = parseBtobSsoInfo(staff_sso);

    if (sso.ssotype == "code") {
      if (isSystemApp()) {
        let params = {
          action: ssoconfig.code_baseurl,
          uid: sso.btob.member_uid,
          pwd: sso.btob.member_pwd,
          url: linkinfo.ssourl,
          type: linkinfo._type,
        }
        const url = browserSiteUrl() + "redirect/btob?p=" + cryptoAES(JSON.stringify(params));
        openExternalWindow(url, "_btob");
      } else {
        let form = document.codessoform;
        openExternalWindow('', target);
        form.action = ssoconfig.code_baseurl;
        form.method = 'POST';
        form.target = target;
        form.elements['UID'].value = sso.btob.member_uid;
        form.elements['PWD'].value = sso.btob.member_pwd;
        if (linkinfo.ssourl) {
          form.elements['URL'].value = linkinfo.ssourl;
        }
        if (linkinfo._type) {
          form.elements['_type'].value = linkinfo._type;
        }
        form.submit();
      }

    } else if (sso.ssotype == "oauth") {
      openExternalWindow(ssoconfig.oauth2_baseurl + sso.btob.oauth_ssoid, target);
    } else if (sso.ssotype == "saml") {
      openExternalWindow(ssoconfig.saml_baseurl + sso.btob.saml_ssoid, target);
    } else if (sso.ssotype == "im") {
      openExternalWindow(ssoconfig.im_baseurl, target);
    } else {
      if (linkinfo.nossourl) {
        openExternalWindow(linkinfo.nossourl, target);
      } else {
        routerPush("/linked_services");
      }
    }
  }
}

// タイムマネジメント遷移
export function redirectTimemanagement(self, redirect_path) {
  const params = {
    time: new Date().getTime(),
    token: storeAuth.storeGetTokenInfo(),
    sys: getSystemType(),
    associate: storeAuth.storeGetKeyAsSelectedAssociate(),
    staff: storeAuth.storeGetKeyAsSelectedStaff(),
    shop: storeAuth.storeGetKeyAsSelectedShop(),
    company_group: storeAuth.storeGetKeyAsSelectedCompanyGroup(),
    company: storeAuth.storeGetKeyAsSelectedCompany(),
    brand: storeAuth.storeGetKeyAsSelectedBrand(),
    display_mode: storeSystem.storeGetDisplayMode(),
    proxy_signin: storeAuth.storeGetAccount("proxy_signin"),
    proxy_from_staff: storeAuth.storeGetAccount("proxy_from_staff"),
    signin_info: storeAuth.storeGetSigninInfo(),
    redirect_path: redirect_path,
  }
  startExecute(self)
  window.location = getTimemanagementUrl() + "receive?p=" + cryptoAES(JSON.stringify(params))
}
export function getTimemanagementUrl() {
  if (window.location.host.includes("localhost")) {
    return "http://localhost:8003/"
  }
  return ssoconfig.timemanage_url
}

// BtoB SSO情報
export function formartBtobSsoInfo(ssotype, btob_info) {
  let data = { ssotype: ssotype };
  if (ssotype == "code") {
    data.member_uid = btob_info.member_uid;
    data.member_pwd = btob_info.member_pwd;
  } else if (ssotype == "oauth") {
    data.oauth_ssoid = btob_info.oauth_ssoid;
  } else if (ssotype == "saml") {
    data.saml_ssoid = btob_info.saml_ssoid;
  }
  return JSON.stringify(data);
}
export function parseBtobSsoInfo(btob_info) {
  let result = {
    ssotype: "none",
    btob: {},
  }
  if (btob_info) {
    let btob = null
    if (btob_info instanceof Array) {
      btob = btob_info.find(v => v.sso_info_cd == "btob")
    } else {
      btob = btob_info
    }
    if (btob) {
      try {
        let data = null
        if (isEnvProd()) {
          data = JSON.parse(btob.sso_info)
        } else {
          try {
            // 開発の旧データ用
            data = JSON.parse(decryptoAES(btob.sso_info));
          } catch {
            data = JSON.parse(btob.sso_info);
          }
        }
        result = {
          ssotype: data.ssotype,
          btob: {
            member_uid: data.member_uid,
            member_pwd: data.member_pwd,
            oauth_ssoid: data.oauth_ssoid,
            saml_ssoid: data.saml_ssoid,
          }
        }
      } catch { } // eslint-disable-line
    }
  }
  return result
}

// 店舗QRスキャン
export function execInviteQrScan() {
  qrApp.postMessage(""); // eslint-disable-line
}
export function checkInviteQr(url) {
  if (url.indexOf("?")) {
    const query_params = url.substring(url.indexOf("?") + 1).split("&")
    const ivc = query_params.find(v => v.startsWith("ivc="))
    if (ivc) {
      storeActivate.storeSetActivateInviteCode(ivc.substring(4))
      routerPush("/setup/invite");
    }
  }
}

// アプリ説明サイト遷移
export function externalTermsOfService() {
  _externalInfo("terms")
}
export function externalTermsOfServiceVManage() {
  _externalInfo("terms_vmanage", getTimemanagementUrl())
}
export function externalPrivacyPolicy() {
  _externalInfo("privacy")
}
export function externalSecurityPolicy() {
  _externalInfo("security")
}
export function externalCompanyInfo() {
  _externalInfo("company")
}
export function externalSendDisciplineGuidelines() {
  _externalInfo("external_send_discipline_guidelines")
}
export function externalEnvironmentInfo() {
  _externalInfo("environment")
}
function _externalInfo(type, hosturl) {
  let url = getExternalInfoUrl(type)
  if (url.indexOf("/") === 0) {
    const host = hosturl ?? getHostURL()
    url = host.endsWith("/") ? host + url.substring(1) : host + url
  }
  openExternalWindow(url);
}
export function getExternalInfoUrl(type) {
  const mode = isDisplayModeSP() ? "sp" : "pc"
  return config.external_info[mode][type]
}

// SSO種別取得
export function getSsoTypes() {
  return ssoconfig.ssotypes;
}

export default {

  // 店舗切り替え後ページ
  getChangeShopBasePage,

  // 承認要求
  approvalRequestApproved,
  approvalRequestDecline,

  // 招待通知方法取得
  getInviteSendTypeName,

  // ヘッダーアイコンリンク
  getPortalHeaderIconLinks,

  // フッターリンク
  getPortalSpFooterLinks,
  // ポータルサービス画像パス
  getPortalServiceImagePath,

  // ポータルメニュー取得
  getPortalMenus,
  getPortalAdminMenus,

  // ポータルメニュー遷移
  portalMenuMove,

  // タイムマネジメント遷移
  redirectTimemanagement,
  getTimemanagementUrl,

  // BtoB SSO情報
  formartBtobSsoInfo,
  parseBtobSsoInfo,

  // 店舗QRスキャン
  execInviteQrScan,
  checkInviteQr,

  // アプリ説明サイト遷移
  externalTermsOfService,
  externalTermsOfServiceVManage,
  externalPrivacyPolicy,
  externalCompanyInfo,
  externalSendDisciplineGuidelines,
  externalEnvironmentInfo,
  _externalInfo,
  getExternalInfoUrl,

  //  SSO種別取得
  getSsoTypes,
}
