import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "employee/list",
            query: { staff_id: staff_id, page: page }
        })
    },
    getMasterList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "master/employee/list",
            query: { staff_id: staff_id, conditions: conditions }
        })
    },
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "employee/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 作成
    create(apiconf, staff_id, employee) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "employee/create",
            query: { staff_id: staff_id, employee: employee },
        })
    },

    // 更新
    update(apiconf, staff_id, employee) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "employee/update",
            query: { staff_id: staff_id, employee: employee },
        })
    },

    // 一覧取得（店舗管理者用）
    getMyShopList(apiconf, staff_id, shop_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/employee/list",
            query: { staff_id: staff_id, shop_id: shop_id, page: page }
        })
    },
    searchMyShop(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/employee/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    // 未確定従業員一覧
    getMyShopPreList(apiconf, staff_id, shop_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/employee/plist",
            query: { staff_id: staff_id, shop_id: shop_id }
        })
    },
}