const HelpPageURL = "https://infomart.notion.site/F-Portal-fc4d89d44a234973a34700f84370582e";

export default {
    // MEMO：サービス別に書き換える
    service_type: "portal",
    maintitle: "F-Portal",
    basepath: "",
    basecolor: {
        base: "#1976D2",   // blue darken-2
        text: "#1976D2",
        background: "#ffffff",
        container_background: "#F8F5F5",
        isdark: false,
        input: "teal lighten-1",
        shop_share_mode: "#ffc65c"
    },
    help_page_url: HelpPageURL,
    menus: {
        home: {
            name: "ホーム",
            to: "/",
        },
        master: {
            name: "データ管理",
            role_cds: ["admin", "officer", "manager"],
        },
        // file: {
        //   name: "ファイル",
        //   to: "/file",
        //   role_cds: ["admin", "officer", "manager"],
        // },
        menus: {
            name: "メニュー",
            to: "/setting/menu",
            sublinks: [
                {
                    name: "アカウント",
                    linked_path: "/account",
                },
                {
                    name: "すべてのサービス",
                    linked_path: "/myapp",
                },
                {
                    name: "連携サービス",
                    linked_path: "/linked_services",
                },
                // {
                //   name: "招待",
                //   linked_path: "/invitation",
                //   policy: { function_cd: "invite", crud: ['c', 'r', 'u', 'd'] }
                // },
                {
                    name: "ヘルプセンター",
                    linked_path: HelpPageURL,
                    external_browser_only: true,
                    // target: "_help"
                },
                {
                    name: "お問い合わせフォーム",
                    linked_path: "/help/contact",
                },
                {
                    name: "プッシュ通知設定",
                    linked_path: "/settings/alert",
                    policy: { is_app: true },
                },
                {
                    name: "アプリについて",
                    linked_path: "/about",
                },
            ]
        },
        admin_menus: {
            name: "管理者メニュー",
            is_head: true,
            role_cds: ['admin'],
            sublinks: [
                {
                    name: "ホーム表示設定",
                    linked_path: "/admin/services",
                },
            ],
        },
    }
}
